import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  finalize,
  firstValueFrom,
  lastValueFrom,
  of,
  Subject,
  switchMap,
  takeUntil,
  throwError
} from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { DialogService } from "../../_base-component/dialog/dialog.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { openDateValidator } from "@modules/validators/open-date-validator";
import { closeDateValidator } from "@modules/validators/close-date-validator";
import { IsCloseDateAfterOpen } from "@app/new-round-wizzard/wizzard-sections/details-section/date-validator";
import { SnackBarService } from "@services/snack-bar.service";
import { StreaksService } from "@services/streaks.service";
import { RoundStatusEnum } from "@enums/RoundStatusEnum";
import { StreakRoundDashboardModel } from "@models/StreakRoundDashboardModel";
import { EventsService } from "@services/events.service";
import moment from "moment";
import { QuestionTypesEnum } from "@enums/QuestionTypesEnum";
import { CorrectAnswersDetailsFormInterface } from "@formModels/correct-answers-details-form-interface";
import { QuestionsBankService } from "@services/questions-bank.service";
import { StreakHelperService } from "@services/streak/streak-helper.service";
import { CorrectAnswerHelperService } from "@services/streak/correct-answer-helper.service";
import { RoundProcessingService } from "@services/round-processing.service";
import { StreakQuestionListItemModel } from "@models/StreakQuestionListItemModel";
import { EventDetailsModel } from "@models/EventDetailsModel";
import { LocalizationService } from "@services/localization.service";

@Component({
	selector: 'edit-streak-round',
	templateUrl: './edit-streak-round.component.html',
	styleUrls: ['./edit-streak-round.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditStreakRoundComponent implements OnInit, OnDestroy {

	questions$ = new BehaviorSubject<StreakQuestionListItemModel[]>(null)

	detailsForm: FormGroup

	validPrevSportEvent: Partial<EventDetailsModel> = {id: 0};

  tabIndex = 0;

	labelArray = ['Streak Day Details'];

	header = 'Edit Game';

	correctAnswersForm: FormGroup<CorrectAnswersDetailsFormInterface>;

	isRoundFinished$ = new BehaviorSubject<boolean>(false)

	isLoaded$ = new BehaviorSubject(false);

	protected readonly QuestionTypesEnum = QuestionTypesEnum;

	private unsubscribe$: Subject<void> = new Subject();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: {round: StreakRoundDashboardModel, sameRoundsIds: string},
		private dialogRef: MatDialogRef<EditStreakRoundComponent>,
		private dialogService: DialogService,
		private isCloseDateAfterOpen: IsCloseDateAfterOpen,
		private streaksService: StreaksService,
		private snackBarService: SnackBarService,
		private eventsService: EventsService,
		private questionStreakService: QuestionsBankService,
		private streakHelperService: StreakHelperService,
		public correctAnswerHelperService: CorrectAnswerHelperService,
		private roundProcessingService: RoundProcessingService,
    private localizationService: LocalizationService,
		private cdr: ChangeDetectorRef,
	) {
	}

	ngOnInit(): void {

		this.initData();

		this.buildForms();

    this.fillForms();

    this.handleDatesValidation();

    this.handleSportEventChange();

	}

  handleDatesValidation() {
    this.detailsForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.isCloseDateAfterOpen.roundDateErrorChecker(this.detailsForm));
  }

  handleSportEventChange() {
    this.detailsForm.get('sportEvent').valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged(),
      switchMap((currentSportEvent) => {
        if (this.detailsForm.get('openDate').invalid) {
          this.detailsForm.get('openDate').patchValue(this.detailsForm.get('openDate').value);
          this.detailsForm.get('openDate').markAsTouched();
          this.detailsForm.get('openDate').updateValueAndValidity();
        }
        currentSportEvent = currentSportEvent ? currentSportEvent : {id: 0};
        if (this.validPrevSportEvent.id !== currentSportEvent.id) {
          const dateNow = Date.now();
          return this.dialogService.open(
            {
              dialogContent: 'The start day of the sport event will be set to the close date field. Are you sure you want to set this sport event date? ',
              labelOk: 'Yes',
              labelNo: 'No'
            }
          ).pipe(
            tap((response) => {
              if (response) {
                if (currentSportEvent && moment(dateNow).isBefore(currentSportEvent.startDate)) {
                  this.detailsForm.get('closeDate').patchValue(this.subtractTwoMinutesFromDate(currentSportEvent.startDate));
                  this.detailsForm.get('closeDate').updateValueAndValidity();
                  this.validPrevSportEvent = currentSportEvent;
                } else if (currentSportEvent) {
                  this.snackBarService.showSnackBar('Cannot assign event as the start date of the event is before current time', true);
                  this.detailsForm.get('sportEvent').patchValue(this.validPrevSportEvent);
                  this.detailsForm.get('sportEvent').updateValueAndValidity()
                }
              } else {
                this.detailsForm.get('sportEvent').patchValue(this.validPrevSportEvent);
                this.detailsForm.get('sportEvent').updateValueAndValidity()
              }
            })
          )
        } else {
          return of(true);
        }
      })
    ).subscribe()
  }

  subtractTwoMinutesFromDate(isoTimestamp: string) {
    const timestamp = new Date(isoTimestamp);
    timestamp.setMinutes(timestamp.getMinutes() - 2);
    return timestamp.toISOString();
  }

	initData() {
		if (this.data) {
			this.isRoundFinished$.next(this.streakHelperService.isRoundFinished(this.data.round.status));
		}
		this.eventsService.loadEvent().pipe(
			switchMap(() => this.isRoundFinished$),
			switchMap((isRoundFinished) => {
				if (isRoundFinished) {
					this.labelArray.push('Questions');
					return this.questionStreakService.fetchQuestionListByRoundId(this.data.round.id);
				}
				return of(null)
			}),
			tap(questionList => {
				if (questionList && questionList.length) {
					this.questions$.next(questionList);
					this.correctAnswerHelperService.formProcessing(questionList);
					this.correctAnswersForm = this.correctAnswerHelperService.correctAnswersForm;
				}
				this.isLoaded$.next(true)
			}),
			takeUntil(this.unsubscribe$),
			catchError((error) => {
				this.snackBarService.showSnackBar(error.error.message, true);
				this.isLoaded$.next(true);
				return throwError(error);
			})
		).subscribe();
	}

	buildForms() {
		this.detailsForm =  new FormGroup({
				name: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(52)]),
				openDate: new FormControl('', Validators.compose([Validators.required, openDateValidator(false)])),
				closeDate: new FormControl('', [Validators.required, closeDateValidator()]),
        sportEvent: new FormControl('', [Validators.required]),
        doublePoints:  new FormControl(false),
			},
			{
				validators: [this.isCloseDateAfterOpen.validateDates()],
			});
	}

	async fillForms() {
		Object.keys(this.data.round).forEach(key => {
			this.detailsForm.get(key)?.patchValue(this.data.round[key]);
		});

    this.validPrevSportEvent = this.data.round.sportEvent ? this.data.round.sportEvent : {id: 0};

    if (this.data.round.status !== RoundStatusEnum.DRAFT && this.data.round.status !== RoundStatusEnum.PENDING) {
      this.detailsForm.get('openDate').disable();
    }

    if (this.data.round.status !== RoundStatusEnum.PENDING && this.data.round.status !== RoundStatusEnum.DRAFT) {
      this.detailsForm.get('sportEvent').disable();
    }

		await firstValueFrom(this.isRoundFinished$.pipe(
			filter(isRoundFinished => isRoundFinished),
			tap(() => {
				this.detailsForm.get('closeDate').disable();
				this.detailsForm.get('name').disable();
        this.detailsForm.get('sportEvent').disable();
        this.detailsForm.get('doublePoints').disable();
			})
		))
  }

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async closeDialog() {
		if (this.detailsForm.dirty && this.detailsForm.touched) {
			await lastValueFrom(
				this.dialogService.open(
					{
						dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
						labelOk: 'Yes',
						labelNo: 'No'
					}
				).pipe(
					filter(response => !!response),
					tap(() => this.dialogRef.close(true))
				)
			)
		} else {
			this.dialogRef.close(true);
		}
	}

	saveRound() {
    const body = this.getRoundBody();
    this.streaksService.editStreakRoundDay(body, this.data.round.id).pipe(
      takeUntil(this.unsubscribe$),
      switchMap((data:{id: number,isRoundNonLocalized?:boolean }) => {
        return this.localizationService.vendorLocales$.pipe(
          map(locales => {
            const isLocalizedPopupDisplayed = this.data.round.id ? data?.isRoundNonLocalized && locales.length : locales.length;
            if (isLocalizedPopupDisplayed) {
              this.dialogService.openLocalizedErrorMessage({id: this.data.round.id }, this.dialogRef).then(() => {
                this.streaksService.needUpdateStreakRounds$.next(true);
              });
            } else {
              this.streaksService.needUpdateStreakRounds$.next(true);
              this.dialogRef.close()
            }
          })
        )
      }),
      catchError((error) => {
        this.snackBarService.showSnackBar(error.error.message, true, 'ok', 10000);
        return throwError(error);
      })
    ).subscribe();

	}


	getRoundBody() {
		const formValue = this.detailsForm.value;
    const roundBody = {
      publish: true,
    }

		const {openDate, closeDate, name, sportEvent, doublePoints} = formValue;

    if (openDate && this.detailsForm.get('openDate').dirty) {
      roundBody['openDate'] = typeof openDate === 'string' ? openDate : openDate.toISOString();
    }

    if (closeDate && this.detailsForm.get('closeDate').dirty) {
      roundBody['closeDate'] = typeof closeDate === 'string' ? closeDate : closeDate.toISOString();
    }

    if (name && this.detailsForm.get('name').dirty) {
      roundBody['name'] = name;
    }

    if (sportEvent) {
      roundBody['sportEventId'] = formValue.sportEvent.id;
    }
    if(this.detailsForm.get('doublePoints').touched) {
      roundBody['doublePoints'] = doublePoints
    }

		return roundBody;
	}

	saveCorrectAnswers () {
		this.isLoaded$.next(false);
		this.roundProcessingService.submitStreakCorrectAnswers(this.data.round.id)
			.pipe(
				takeUntil(this.unsubscribe$),
				tap(() => {
					this.snackBarService.showSnackBar('Answers submitted');
					this.streaksService.needUpdateStreakRounds$.next(true);
					this.dialogRef.close(true);
				}),
				finalize(() => this.isLoaded$.next(true)),
				catchError((error) => {
					this.snackBarService.showSnackBar(error.error.message, true);
					return throwError(error);
				}),
			)
			.subscribe();
	}

	isDisable () {
		return combineLatest([
			this.correctAnswerHelperService.isCorrectAnswerFormValid$,
			of(this.correctAnswerHelperService.correctAnswersForm?.dirty),
		]).pipe(
			map(([isCorrectAnswerFormValid, isDirty]) => {
				return !(isCorrectAnswerFormValid && isDirty);
			})
		)
	}

	selectTab($event) {
		this.isLoaded$.next(false);
		this.tabIndex = $event.index;
		this.cdr.detectChanges();
		this.isLoaded$.next(true);
	}

}
