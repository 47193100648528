import {Component, EventEmitter, Input, Output} from '@angular/core';
import { SnackBarService } from "@services/snack-bar.service";

@Component({
  selector: 'image-with-loader',
  templateUrl: './image-with-loader.component.html',
  styleUrls: ['./image-with-loader.component.scss']
})
export class ImageWithLoaderComponent {
  @Input() imageSrc: string;

  @Input() overlayHeight = '100%';

  @Input() fixedOverleyWidth = '100%';

  @Input() spinnerSize: number;

  @Input() additionClass: string[];

  @Input() additionOverlayClass: string[];

  @Input() showFlipHorizontally: boolean;

  @Output() imageLoaded = new EventEmitter<void>();

  @Output() flippedImage = new EventEmitter<FormData>();

  isImageLoading = false;

  constructor(
    private snackBarService: SnackBarService
  ) {
  }

  /**
   * Event when image loaded in browser
   */
  onImageLoaded () {
    this.isImageLoading = true;
    this.imageLoaded.emit();
  }

  /**
   * If patent component has specific styles for current image, show this style
   */
  getClassName () {
    const classes = {};
    if (this.additionClass) {
      this.additionClass.forEach((className) => {
        classes[className] = true;
      });
    }
    return classes;
  }

  /**
   * If patent component has specific styles for current loader, show this style
   */
  getOverlayClassName() {
    const classes = {};
    if (this.additionOverlayClass) {
      this.additionOverlayClass.forEach((className) => {
        classes[className] = true;
      });
    }
    return classes;
  }

  flipHorizontally() {
    this.isImageLoading = false;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      this.onFlipError();
      return;
    }

    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = this.imageSrc;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.scale(-1, 1);
      ctx.drawImage(image, -image.width, 0);
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const file = new File([blob], 'flipped-image.png', { type: 'image/png' });
            const formData = new FormData();
            formData.append('file', file);
            this.flippedImage.emit(formData);
          } else {
            this.onFlipError();
          }
        },
        'image/png',
        1.0
      );
    };

    image.onerror = () => {
      this.onFlipError();
    };
  }

  onFlipError() {
    this.isImageLoading = true;
    this.snackBarService.showSnackBar('Image flip failed', true);
  }
}
