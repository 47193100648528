import { APP_DATA } from "../../../general.app.config";

const VALIDATOR_TO_ERROR_KEY = {
  required: APP_DATA.MANDATORY,
  minlength_8: APP_DATA.MIN_LENGHT,
  minlength_1: APP_DATA.MIN_LENGHT_1,
  minlength_2: APP_DATA.MIN_LENGHT_2,
  minlength_3: APP_DATA.MIN_LENGHT_3,
  maxlength_52: APP_DATA.MAX_LENGHT_52,
  maxlength_20: APP_DATA.MAX_LENGHT_20,
  maxlength_50: APP_DATA.MAX_LENGHT_50,
  maxlength_255: APP_DATA.MAX_LENGHT_255,
  min: APP_DATA.MIN_VALUE,
  max_long: APP_DATA.MAX_VALUE_LONG,
  max: APP_DATA.MAX_VALUE,
  invalidOpenDate: APP_DATA.OPEN_DATE_ERROR,
  invalidCloseDate: APP_DATA.CLOSE_DATE_ERROR,
  incorrect: APP_DATA.DATES_ERROR,
  incorrectStartDate: APP_DATA.DATES_ERROR_START,
  requiredAnswersLength_2: APP_DATA.TWO_ANSWERS_ONLY,
  minAnswersLength_3: APP_DATA.THREE_ANSWERS,
  minAnswersLength_2: APP_DATA.TWO_ANSWERS,
  minValue: APP_DATA.RANGE_MIN_VALUE_ERROR,
  incrementValue: APP_DATA.RANGE_INCREMENTAL_VALUE_ERROR,
  incrementValueAliquot: APP_DATA.RANGE_INCREMENTAL_ALIQUOT_ERROR,
  invalidRangeValue: APP_DATA.IVALID_VALUE,
  mustIncludePlus: APP_DATA.SHOULD_CONTAIN_PLUS,
  pattern: APP_DATA.IVALID_VALUE,
  patternSpace: APP_DATA.SPACE_END,
  doubleSpace: APP_DATA.DOUBLE_SPACE,
  invalidValue: APP_DATA.IVALID_VALUE,
  decimalOrNegativeNumber: APP_DATA.INTEGER_VALUE,
  maxPointsGreaterThanMinPoints: APP_DATA.MIN_MAX_POINTS_ERROR,
  invalidPoints: APP_DATA.MIN_POINTS_ERROR,
  invalidMaxPoints: APP_DATA.MAX_POINTS_ERROR,
  invalidTierPrize: APP_DATA.TIERS_PRIZE_ERROR,
  invalidTierExactPoints: APP_DATA.TIERS_EXACT_POINTS_ERROR,
  zeroValue: APP_DATA.ZERO_VALUE_ERROR,
  maxPositionGreaterThanMinPosition: APP_DATA.MIN_MAX_POSITION_ERROR,
  invalidMinPosition: APP_DATA.MIN_POSITION_ERROR,
  invalidMaxPosition: APP_DATA.MAX_POSITION_ERROR,
  forbiddenIP: APP_DATA.FORBIDDEN_IP
}

export function getErrorFromValidator(validatorName, validatorAdditionlData) {
  switch (validatorName) {
    case 'minAnswersLength':
      return VALIDATOR_TO_ERROR_KEY[`${validatorName}_${validatorAdditionlData.minLength}`];
    case 'incorrect':
    case 'invalidMaxPoints':
    case 'invalidTierExactPoints':
    case 'invalidTierPrize':
    case 'incorrectStartDate':
    case 'zeroValue':
    case 'required':
    case 'invalidOpenDate':
    case 'invalidPoints':
    case 'invalidCloseDate':
    case 'minValue':
    case 'incrementValue':
    case 'incrementValueAliquot':
    case 'invalidRangeValue':
    case 'mustIncludePlus':
    case 'doubleSpace':
    case 'maxPointsGreaterThanMinPoints':
    case 'maxPositionGreaterThanMinPosition':
    case 'invalidMinPosition':
    case 'forbiddenIP':
    case 'invalidMaxPosition':
      return VALIDATOR_TO_ERROR_KEY[validatorName];
    case 'max':
      if (validatorAdditionlData.max === 1000000000) {
        return VALIDATOR_TO_ERROR_KEY[validatorName + '_long'] + validatorAdditionlData.max;
      } else {
        return VALIDATOR_TO_ERROR_KEY[validatorName] + validatorAdditionlData.max;
      }
    case 'min':
      return VALIDATOR_TO_ERROR_KEY[validatorName] + validatorAdditionlData.min;
    case 'maxlength':
    case 'minlength':
    case 'requiredAnswersLength':
      return VALIDATOR_TO_ERROR_KEY[`${validatorName}_${validatorAdditionlData.requiredLength}`];
    case 'pattern':
      if (validatorAdditionlData.requiredPattern === '/.*\\S$/') {
        return VALIDATOR_TO_ERROR_KEY[validatorName + 'Space'];
      } else {
        return VALIDATOR_TO_ERROR_KEY[validatorName];
      }
    case 'owlDateTimeParse':
      return VALIDATOR_TO_ERROR_KEY.invalidValue;
    case 'decimalOrNegativeNumber':
      return VALIDATOR_TO_ERROR_KEY[validatorName]
  }
}
