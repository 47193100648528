import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { PredictionQuestionFormSectionService } from '../../prediction-question-section-form.service';
import { catchError, lastValueFrom, of, switchMap, take, tap, throwError } from 'rxjs';
import { DialogService } from 'src/app/common/components/_base-component/dialog/dialog.service';
import { RoundHelperService } from '../../round-helper.service';
import { QuestionService } from "@services/question.service";
import { SnackBarService } from "@services/snack-bar.service";
import { SelectOption } from "@components/_base-component/select/select.component";

@Component({
  selector: 'prediction-questions-section',
  templateUrl: './prediction-questions-section.component.html',
  styleUrls: ['./prediction-questions-section.component.scss']
})
export class PredictionQuestionsSectionComponent implements OnInit {

  questionForm: FormGroup;

  @Input() questionOptions: SelectOption[];

  @Input() locales: {i18n: string, id: number}[];


  constructor(
    private questionFormSectionService: PredictionQuestionFormSectionService,
    private rootFormGroup: FormGroupDirective,
    private roundHelperService: RoundHelperService,
    private dialogService: DialogService,
    private questionService: QuestionService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.questionForm = this.rootFormGroup.control || this.rootFormGroup.form;
  }

  getFromArray() {
    return (this.questionForm?.controls?.["questions"] as FormArray)?.['controls'] || [];
  }

  onMainChanged(index: number): void {
    if (!this.roundHelperService.curentRound) {
      this.getFromArray().forEach((control, i) => {
        if (i !== index && control.get('isMain').value) {
          const id = control.getRawValue().id;
          if (id) {
            this.dialogService.openSetMainQuestionConfirmation()
              .pipe(
                switchMap((response) => {
                  if (response) {
                    const body = { id, isMain: false }
                    return this.questionService.updateQuestion(id, body).pipe(
                      tap(() => {
                        control.get('isMain')?.patchValue(false);
                        control.get('isMain').markAsPristine();
                        control.get('isMain').markAsUntouched();
                      }),
                      catchError((error) => {
                        this.snackBarService.showSnackBar(error.error.message, true);
                        return throwError(error);
                      })
                    );
                  } else {
                    this.getFromArray()[index].get('isMain').patchValue(false);
                    this.getFromArray()[index].get('isMain').markAsPristine();
                    this.getFromArray()[index].get('isMain').markAsUntouched();
                    return of(true);
                  }
                }),
                take(1)
              ).subscribe()
          } else {
            control.get('isMain')?.patchValue(false);
          }
        }
      });
    } else {
      this.getFromArray().forEach((control, i) => {
        if (i !== index && control.get('isMain').value) {
          control.get('isMain')?.patchValue(false);
        }
      });
    }

  }

  addQuestionForm() {
    (this.questionForm.controls["questions"] as FormArray)
      .push(this.questionFormSectionService.buildForm(this.roundHelperService.isMultiEventRound));
  }

  async removeQuestionForm(index: number) {

    const result = await lastValueFrom(
      this.dialogService.openDeleteConfirmationPopup(`this question`)
    );
    if (result) {
      (this.questionForm.controls["questions"] as FormArray).removeAt(index);
    }
  }

  up(index) {
    if (
      (this.questionForm?.controls['questions'] as FormArray).controls[index] &&
      (this.questionForm?.controls['questions'] as FormArray).controls[index-1]
    ) {
      const currentControl = (this.questionForm?.controls['questions'] as FormArray).controls[index];
      (this.questionForm?.controls['questions'] as FormArray)
        .controls[index] = (this.questionForm?.controls['questions'] as FormArray).controls[index-1];
      (this.questionForm?.controls['questions'] as FormArray).controls[index-1] = currentControl;
    } else if(index == 0) {
      const maxIndex = (this.questionForm?.controls['questions'] as FormArray).controls.length - 1;
      const currentControl = (this.questionForm?.controls['questions'] as FormArray).controls[0];
      for(let index = 0; index < maxIndex; index++) {
        (this.questionForm?.controls['questions'] as FormArray).controls[index] =
          (this.questionForm?.controls['questions'] as FormArray).controls[index+1];
      }
      (this.questionForm?.controls['questions'] as FormArray).controls[maxIndex] = currentControl;
    }
  }

  down(index) {
    if (
      (this.questionForm?.controls['questions'] as FormArray).controls[index] &&
      (this.questionForm?.controls['questions'] as FormArray).controls[index+1]
    ) {
      const currentControl = (this.questionForm?.controls['questions'] as FormArray).controls[index];
      (this.questionForm?.controls['questions'] as FormArray)
        .controls[index] = (this.questionForm?.controls['questions'] as FormArray).controls[index+1];
      (this.questionForm?.controls['questions'] as FormArray).controls[index+1] = currentControl;
    } else {
      const maxIndex = (this.questionForm?.controls['questions'] as FormArray).controls.length - 1;
      const currentControl = (this.questionForm?.controls['questions'] as FormArray).controls[maxIndex];
      for(let index = maxIndex; index > 0; index--) {
        (this.questionForm?.controls['questions'] as FormArray).controls[index] =
          (this.questionForm?.controls['questions'] as FormArray).controls[index-1];
      }
      (this.questionForm?.controls['questions'] as FormArray).controls[0] = currentControl;
    }
  }

}
