import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/common/components/_base-component/dialog/dialog.service';
import { filter, takeUntil, tap } from "rxjs/operators";
import { catchError, Subject, throwError } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SnackBarService } from "../../../../core/services/snack-bar.service";
import { closeDateValidator } from "../../../modules/validators/close-date-validator";
import { IsCloseDateAfterOpenService } from "../../../../core/services/leaderboard-date-validator.service";
import { openDateValidator } from "../../../modules/validators/open-date-validator";
import { UpsellService } from "../../../../core/services/upsell.service";
import { UpsellModel } from "../../../models/UpsellModel";
import { UpsellStatusStatusEnum } from "../../../Enums/UpsellStatusStatusEnum";

@Component({
  selector: 'create-upsell-banner',
  templateUrl: './create-upsell-banner.component.html',
  styleUrls: ['./create-upsell-banner.component.scss'],
})
export class CreateUpsellBannerComponent implements OnInit, OnDestroy {

  form: FormGroup;

  tooltipTextIcon = 'We recommend using a 128px x 128px image resolution. If you want to upload banner use 1280px x 512px image resolution. Max image size is 4MB';

  private unsubscribe$ = new Subject<void>();


  constructor(
    public dialogRef: MatDialogRef<CreateUpsellBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpsellModel,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
    private isCloseDateAfterOpenService: IsCloseDateAfterOpenService,
    private upsellService: UpsellService,
  ) {}

  ngOnInit(): void {
    this.buildForm();

    if (this.data) {
      this.fillForm();
    }

    this.handleDatesChange();
  }

  buildForm() {
    this.form = new FormGroup({
      imageId: new FormControl(0),
      imageUrl: new FormControl('', [Validators.required]),
      buttonName: new FormControl(null, [Validators.maxLength(20)]),
      displayFrom: new FormControl('', [Validators.required, openDateValidator(false)]),
      displayTo: new FormControl('', [Validators.required, closeDateValidator()]),
      webLink: new FormControl('', [Validators.required]),
      appLink: new FormControl('', [Validators.required]),
      content: new FormControl({}),
      title: new FormControl(null, [Validators.maxLength(20)]),
      description: new FormControl(null, [Validators.maxLength(100)]),
    }, {
      validators: [this.isCloseDateAfterOpenService.validateDates('displayFrom', 'displayTo')],
    })
  }

  checkFormValidity() {
    if (!this.form.dirty) return true;

    if (!this.data || this.data?.status == UpsellStatusStatusEnum.PENDING || this.form.get('displayFrom').touched) {
      return this.form.invalid;
    }
    const formCopy = { ...this.form.value };
    delete formCopy.displayFrom;
    return !Object.keys(formCopy).every(key => this.form.get(key)?.valid);
  }

  fillForm() {
    Object.keys(this.data).forEach(key => {
       if(this.form.get(key)) {
         if(this.data[key] && Object.prototype.hasOwnProperty.call(this.data[key], key)) {
           this.form.get(key).patchValue(this.data[key][key])
         } else {
           this.form.get(key).patchValue(this.data[key])
         }
       }
    })
  }

  handleDatesChange() {
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.dateErrorChecker(this.form));
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  close() {
    if (!this.form.dirty) {
      this.dialog.closeAll();
    } else {
      this.dialogService.open(
        {
          dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
          labelOk: 'Yes',
          labelNo: 'No'
        }
      ).pipe(
        takeUntil(this.unsubscribe$),
        filter(response => !!response),
        tap((response) => {
          if (response) {
            this.dialog.closeAll();
          }
        })
      ).subscribe()
    }
  }

  prepareBody(body) {
    return {
      ...body,
      title: body.title ? body.title : null,
      description: body.description ? body.description : null,
      buttonName: body.buttonName ? body.buttonName : null
    }
  }

  saveBanner() {
    const body = this.prepareBody({...this.form.value});
    if (this.data?.status === UpsellStatusStatusEnum.ACTIVE && !this.form.get('displayFrom').touched) {
      delete body.displayFrom;
    }

    const request = this.data ? this.upsellService.updateUpsellBanner(body, this.data.id) : this.upsellService.createUpsellBanner(body);

    request
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.upsellService.needUpdateUpsellContent$.next(true);
          this.snackBarService.showSnackBar(`The upsell was ${this.data ? 'edited' : 'created'} successfully!`)
          this.dialog.closeAll();
        }),
        catchError((error) => {
          if (error.error) {
            this.snackBarService.showSnackBar(error.error.message, true)
          }
          return throwError(error)
        })
      )
      .subscribe()
  }

  dateErrorChecker(form: FormGroup) {
    if (form.errors?.datesOrderWrong) {
      form.controls['displayTo'].setErrors({'incorrect': true});
      form.controls['displayFrom'].setErrors({'incorrectStartDate': true});
      form.controls['displayFrom'].markAsTouched();
      form.controls['displayTo'].markAsTouched();
    } else {
      const openDateError = form.controls['displayFrom'].errors;
      const closeDateError = form.controls['displayTo'].errors;
      if (closeDateError && closeDateError['incorrect']) {
        delete closeDateError['incorrect'];
        form.controls['displayTo'].patchValue(form.controls['displayTo'].value);
      }

      if (openDateError && openDateError['incorrectStartDate']) {
        delete openDateError['incorrectStartDate'];
        form.controls['displayFrom'].patchValue(form.controls['displayFrom'].value);
      }


      if (openDateError) {
        form.controls['displayFrom'].setErrors(null);
        form.controls['displayFrom'].setErrors(openDateError);
      }
      if (closeDateError) {
        form.controls['displayTo'].setErrors(null);
        form.controls['displayTo'].setErrors(closeDateError);
      }
    }

    if (form.controls['displayFrom'].value
      && form.controls['displayTo'].value
      && !form.errors?.datesOrderWrong
      && !form.controls['displayFrom'].getError('invalidOpenDate')
      && !form.controls['displayTo'].getError('invalidCloseDate')
    ) {
      form.controls['displayFrom'].setErrors(null);
      form.controls['displayTo'].setErrors(null);
    }

    return form;
  }
}
