import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from "./dialog.component";
import { DialogModel } from "@models/DialogModel";
import { filter, take, tap } from "rxjs/operators";
import { lastValueFrom, map, of, switchMap } from 'rxjs';
import { RoundTranslationWizzardComponent } from "../../_common-modal-components/round-translation-wizzard/round-translation-wizzard.component";
import { CustomLeaderboardService } from "@services/custom-leaderboard.service";
import { CustomLeaderboardDetailsModel } from "@models/CustomLeaderboardDetailsModel";
@Injectable({
  providedIn: 'root'
})
export class DialogService {
  dialogRef: MatDialogRef<any>;

  closeDialogRef: MatDialogRef<DialogComponent>;

  customDialogRef: MatDialogRef<any>;


  constructor(
    public dialog: MatDialog,
    private customLeaderboardService: CustomLeaderboardService,
  ) {}

  open(dialogConfig: DialogModel, panelClass = 'custom-dialog-box') {
    const defaultConfig = new DialogModel;
    const config = {...defaultConfig, ...dialogConfig}
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass: panelClass,
    });
    return this.dialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }

  openDeleteConfirmationPopup(deletedItem) {
    const config = {
      ...new DialogModel,
      ...{
        dialogContent: `Are you sure you want to delete ${deletedItem}?`,
        labelOk: 'Yes',
        labelNo: 'No'
      }
    }
    this.closeDialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass: ['custom-dialog-box'],
    });
    return this.closeDialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }

  async openLocalizedErrorMessage(round: {id: number}, dialogRef?: MatDialogRef<any>) {
    await lastValueFrom(
      this.open(
        {
          dialogContent: `Some translations are missing for this game. Are you sure you want to continue?`,
          labelOk: 'Go Back to translate',
          labelNo: 'Continue anyway'
        }, 'custom-dialog-box-localization'
      ).pipe(
        tap((response) => {
          this.customDialogRef ? this.customDialogRef.close() : dialogRef.close();
          if (response) {
            this.dialog
              .open(RoundTranslationWizzardComponent, {
                maxHeight: '960px',
                maxWidth: '1600px',
                height:'90%',
                width: '90vw',
                data: round,
                disableClose: true,
                autoFocus: false
              })
          }
        })
      )
    )
  }

  async openLocalizedPredictorErrorMessage() {
    return await lastValueFrom(
      this.open(
        {
          dialogContent: `Some translations are missing for this game. Are you sure you want to continue?`,
          labelOk: 'Go Back to translate',
          labelNo: 'Continue anyway'
        }, 'custom-dialog-box-localization'
      )
    )
  }

  openRoundTranslationWizzard(round: {id: number}) {
    if(this.customDialogRef)  this.customDialogRef.close()
     return this.dialog
        .open(RoundTranslationWizzardComponent, {
          maxHeight: '960px',
          maxWidth: '1600px',
          height:'90%',
          width: '90vw',
          data: round,
          disableClose: true,
          autoFocus: false
        }).afterClosed().pipe(take(1))
  }


  //eslint-disable-next-line
  async openLocalizedCLErrorMessage(leaderboard, dialogRef?: MatDialogRef<any>, isStreak = false) {
    await lastValueFrom(
      this.open(
        {
          dialogContent: `Some translations are missing for this custom leaderboard. Are you sure you want to continue?`,
          labelOk: 'Go Back to translate',
          labelNo: 'Continue anyway'
        }, 'custom-dialog-box-localization'
      ).pipe(
        switchMap((response) => {
          dialogRef.close();
          if (response) {
            leaderboard = {...leaderboard, leaderboardName: leaderboard.name};
            const componentRef =  this.dialog
              .open(RoundTranslationWizzardComponent, {
                maxHeight: '960px',
                maxWidth: '1600px',
                height:'90%',
                width: '90vw',
                data: {...leaderboard, leaderboardTranslate: true},
                disableClose: true,
                autoFocus: false
              })


            return this.handleDataChange(componentRef)
          } else {
            return of(true);
          }
        })
      )
    )
  }


  private handleDataChange(componentRef: MatDialogRef<RoundTranslationWizzardComponent>) {
    return componentRef.componentInstance.dataChange
      .pipe(
        tap(() => {
          this.customLeaderboardService.isNeedUpdateLeaderboardsList$.next(true)
        }),
        switchMap((id) => {
          return this.customLeaderboardService.leaderboardsListSubject$.pipe(
            tap((customLeaderboards: CustomLeaderboardDetailsModel[]) => {
              const currentCustomLeaderboard = customLeaderboards.find(res => res.id === id);
              if(componentRef.componentInstance){
                componentRef.componentInstance.updateData(JSON.parse(JSON.stringify({ ...componentRef.componentInstance.data, ...currentCustomLeaderboard })));
              }
            })
          );
        })
      );
  }


  async closeModal() {
    await lastValueFrom(
      this.open(
        {
          dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
          labelOk: 'Yes',
          labelNo: 'No'
        }
      ).pipe(
        filter(response => !!response),
        tap(() => this.customDialogRef.close())
      )
    )
  }

  confirmSlackChangesModal() {
    return this.open(
      {
        dialogContent: 'Are you sure you want to disable Slack notifications? Please note that Slack notifications will be disabled for all vendors.',
        labelOk: 'Yes',
        labelNo: 'No'
      }
    );
  }

  hardCloseModal() {
    this.customDialogRef.close();
  }

  openClearCacheConfirmationPopup() {
    const config = {
      ...new DialogModel,
      ...{
      dialogContent: `Are you sure you want to clear cache?`,
        labelOk: 'Yes',
        labelNo: 'No'
      }
    }
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass:['custom-dialog-box'],
    });
    return this.dialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }

  openSubmissionReportToS3ConfirmationPopup(reportName: string) {
    const config = {
      ...new DialogModel,
      ...{
        dialogContent: `Are you sure you want to send the report for the round: ${reportName}?`,
        labelOk: 'Yes',
        labelNo: 'No'
      }
    }
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass:['custom-dialog-box'],
    });
    return this.dialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }

  openShowOnLandingFeatureInfoModalWarning() {
    const config = {
      ...new DialogModel,
      ...{
        dialogContent: `Are you sure you want to enable the info pop-up for all users on their next visit?`,
        labelOk: 'Yes',
        labelNo: 'No'
      }
    }
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass:['custom-dialog-box'],
    });
    return this.dialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }

  navigateFromRoundCreateModalConfirmation() {
    const config = {
      ...new DialogModel,
      ... {
        dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
        labelOk: 'Yes',
        labelNo: 'No'
      }
    }
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass:['custom-dialog-box'],
    });
    return this.dialogRef.afterClosed()
      .pipe(
        take(1),
        map(result => !!result)
      );
  }



  openDisableQuestionConfirmationPopup(message: string) {
    const config = {
      ...new DialogModel,
      ...{
        dialogContent: `Are you sure you want to ${message}`,
        labelOk: 'Yes',
        labelNo: 'No'
      }
    }
    this.closeDialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass: ['custom-dialog-box'],
    });
    return this.closeDialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }

  openSetMainQuestionConfirmation() {
    const config = {
      ...new DialogModel,
      ...{
        dialogContent: `Are you sure you want to select this question as prize receiving? The question that you selected for receiving prize before will be aborted`,
        labelOk: 'Yes',
        labelNo: 'No'
      }
    }
    this.closeDialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass: ['custom-dialog-box'],
    });
    return this.closeDialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }
}
