import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollControlService {
  private openedElements: Set<Element> = new Set();

  openElement(element: Element) {
    this.openedElements.add(element);
    this.disableScroll();
  }

  closeElement(element: Element) {
    this.openedElements.delete(element);
    if (this.openedElements.size === 0) {
      this.enableScroll();
    }
  }

  private disableScroll() {
    const bodyElement = document.getElementsByClassName('scroll-container')[0] as HTMLElement;
    if (bodyElement) {
      bodyElement.style.overflow = 'hidden';
    }
  }

  private enableScroll() {
    const bodyElement = document.getElementsByClassName('scroll-container')[0] as HTMLElement;
    if (bodyElement) {
      bodyElement.style.overflow = '';
    }
  }
}
