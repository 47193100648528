import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { UriConfig } from "@app/app.config";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { WhitelistedUsersModel } from "@models/WhitelistedUsersModel";

@Injectable({
  providedIn: 'root'
})
export class WhitelistedUsersService {

  needUpdateWhitelistedUsers$ = new BehaviorSubject(true);

  ipsList$ = new BehaviorSubject([]);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

	getWhitelistedUsers(requestBody): Observable<{ records: WhitelistedUsersModel[], total: number }> {
		let params = new HttpParams()
		Object.keys(requestBody).forEach(param => {
			params = params.set(param, requestBody[param])
		})

		return this.http.get<{ records: WhitelistedUsersModel[], total: number }>(this.uriConfig.whitelistedUsers, {params})
      .pipe(
        tap((data) => {
          this.ipsList$.next(data.records.map(item => item.ip));
        })
      );
	}

  createWhitelistedUser(body: Partial<WhitelistedUsersModel>) {
    return this.http.post(this.uriConfig.whitelistedUsers , body);
  }

  updateWhitelistedUser(body: Partial<WhitelistedUsersModel>, id: number) {
    return this.http.patch(this.uriConfig.whitelistedUsers + '/' + id, body);
  }

  deleteWhitelistedUser(id: number) {
    return this.http.delete(this.uriConfig.whitelistedUsers + '/' + id);
  }
}
