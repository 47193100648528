import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/common/components/_base-component/dialog/dialog.service';
import { filter, takeUntil, tap } from "rxjs/operators";
import { catchError, Subject, throwError } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SnackBarService } from "@services/snack-bar.service";
import { WhitelistedUsersModel } from "@models/WhitelistedUsersModel";
import { WhitelistedUsersService } from "@services/whitelisted-users.service";
import { textNotInArrayValidator } from "@components/_common-modal-components/create-whitelisted-user/ip-validator";

@Component({
  selector: 'create-whitelisted-user',
  templateUrl: './create-whitelisted-user.component.html',
  styleUrls: ['./create-whitelisted-user.component.scss'],
})
export class CreateWhitelistedUserComponent implements OnInit, OnDestroy {

  form: FormGroup;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CreateWhitelistedUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WhitelistedUsersModel,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
    private whitelistedUsersService: WhitelistedUsersService,
  ) {}

  ngOnInit(): void {
    this.buildForm();

    if (this.data) {
      this.fillForm();
    }
  }

  buildForm() {
    const existingIps = this.data
      ? this.whitelistedUsersService.ipsList$.value.filter(item => item !== this.data.ip)
      : this.whitelistedUsersService.ipsList$.value;
    this.form = new FormGroup({
      ip: new FormControl('', [Validators.required, textNotInArrayValidator(existingIps)]),
      description: new FormControl('', [Validators.required]),
    })
  }

  fillForm() {
    Object.keys(this.data).forEach(key => {
       if(this.form.get(key)) {
         if(this.data[key] && Object.prototype.hasOwnProperty.call(this.data[key], key)) {
           this.form.get(key).patchValue(this.data[key][key])
         } else {
           this.form.get(key).patchValue(this.data[key])
         }
       }
    })
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    if (!this.form.dirty) {
      this.dialog.closeAll();
    } else {
      this.dialogService.open(
        {
          dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
          labelOk: 'Yes',
          labelNo: 'No'
        }
      ).pipe(
        takeUntil(this.unsubscribe$),
        filter(response => !!response),
        tap((response) => {
          if (response) {
            this.dialog.closeAll();
          }
        })
      ).subscribe()
    }
  }

  saveWhitelistUser() {
    const body = this.form.value;

    const request = this.data ? this.whitelistedUsersService.updateWhitelistedUser(body, this.data.id) : this.whitelistedUsersService.createWhitelistedUser(body);

    request
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.whitelistedUsersService.needUpdateWhitelistedUsers$.next(true);
          if (this.data) {
            this.snackBarService.showSnackBar(`The whitelist record has been successfully updated`)
          } else {
            this.snackBarService.showSnackBar(`The new IP address has been successfully added to the whitelist`)
          }
          this.dialog.closeAll();
        }),
        catchError((error) => {
          if (error.error) {
            this.snackBarService.showSnackBar(error.error.message, true)
          }
          return throwError(error)
        })
      )
      .subscribe()
  }
}
