import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function textNotInArrayValidator(forbiddenValues: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value || !forbiddenValues) return null;

    // Check if the value matches any of the forbidden values
    const isForbidden = forbiddenValues.includes(value);

    return isForbidden ? { forbiddenIP: { value } } : null;
  };
}
