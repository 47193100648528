import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {UriConfig} from "@app/app.config";
import { BehaviorSubject, Observable, tap } from "rxjs";
import {EventDetailsModel} from "@models/EventDetailsModel";
import {TeamDetailsModel, CreateTeamDetailsModel} from "@models/TeamDetailsModel";
import {CreateEventDetailsModel} from "@models/CreateEventDetailsModel";
import { ExternalEventResponseDetailModel } from "@models/external/ExternalEventResponseDetailModel";

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  isNeedUpdate$ = new BehaviorSubject<boolean>(true);

  isNeedUpdateExternalEventsList$ = new BehaviorSubject<boolean>(true);

  isNeedUpdateTeamList$ = new BehaviorSubject<boolean>(true);

  eventList$ = new BehaviorSubject<EventDetailsModel[]>([]);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

  getEventsList(requestBody =  {}): Observable<{records: EventDetailsModel[], total: number }> {
    let params = new HttpParams()
    Object.keys(requestBody).forEach(param => {
      params = params.set(param, requestBody[param])
    })
    return this.http.get<{records: EventDetailsModel[], total: number }>(this.uriConfig.sportEvents, { params });
  }

  loadEvent() {
    return this.getEventsList().pipe(
      tap(response => {
        this.eventList$.next(response.records);
      })
    )
  }

  createNewEvent(event: CreateEventDetailsModel): Observable<EventDetailsModel> {
    return this.http.post<any>(this.uriConfig.sportEvents, event);
  }

  deleteEventById(eventId:number) {
    return this.http.delete<any>(this.uriConfig.sportEvents + '/' + eventId);
  }

  updateEvent(eventId: number, event: CreateEventDetailsModel): Observable<EventDetailsModel> {
    return this.http.patch<any>(this.uriConfig.sportEvents + '/' + eventId, event);
  }

  getTeamsList(): Observable<TeamDetailsModel[]> {
    return this.http.get<TeamDetailsModel[]>(this.uriConfig.teams);
  }

  createNewTeam (body): Observable<TeamDetailsModel> {
    return this.http.post<any>(this.uriConfig.teams, body);
  }

  deleteTeamById(teamId:number) {
    return this.http.delete<any>(this.uriConfig.teams + '/' + teamId);
  }

  updateTeam(body: CreateTeamDetailsModel, teamId: number): Observable<TeamDetailsModel> {
    return this.http.patch<any>(this.uriConfig.teams + '/' + teamId, body);
  }

  getExternalEvents(body):Observable<ExternalEventResponseDetailModel> {
    let params = new HttpParams()
    Object.keys(body).filter(v => body[v]).forEach(param => {
      params = params.set(param, body[param])
    });
    return this.http.get<ExternalEventResponseDetailModel>(this.uriConfig.externalSportEvents, { params });
  }

  needUpdateEventList() {
    this.isNeedUpdate$.next(true);
  }

  getEventListStatus() {
    return this.isNeedUpdate$;
  }


  needUpdateTeamList() {
    this.isNeedUpdateTeamList$.next(true);
  }

  getTeamListStatus() {
    return this.isNeedUpdateTeamList$;
  }
}
