import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  NonNullableFormBuilder, ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {
  CorrectAnswersDetailsFormInterface,
  SingleAnswersDetailsFormInterface
} from "../../../common/FormsModels/correct-answers-details-form-interface";
import { BehaviorSubject, lastValueFrom, pairwise, startWith, tap } from "rxjs";
import { QuestionDetailsModel, VoidType } from "../../../common/models/QuestionDetailsModel";
import { QuestionTypesEnum } from "../../../common/Enums/QuestionTypesEnum";
import { decimalOrNegativeValidator } from "../../../common/modules/validators/decimal-or-negative-validator";
import { StreakQuestionListItemModel } from "../../../common/models/StreakQuestionListItemModel";
import { StreakQuestionDifficultyEnum } from "../../../common/Enums/StreakQuestionDifficultyEnum";
import { TextService } from "../text.service";

function validateControls(control1Name: string, control2Name: string): ValidatorFn {
  return (group: AbstractControl): { [key: string]: any } | null => {
    const control1 = group.get(control1Name);
    const control2 = group.get(control2Name);

    if (control1 && control2) {
      const value1 = control1.value;
      const value2 = control2.value;

      const hasOneValue = (value1 && !value2) || (!value1 && value2);
      const control1Errors = {...control1.errors};
      const control2Errors = {...control2.errors};

      if (hasOneValue) {
        if (value1) {
          control2Errors.required = true;
          control2.markAsTouched();
        } else {
          delete control2Errors.required;
        }
        if (value2) {
          control1Errors.required = true;
          control1.markAsTouched();
        } else {
          delete control1Errors.required;
        }
      }

      if (control1.touched && !value1) {
        control1Errors.required = true;
      } else if (value1) {
        delete control1Errors.required;
      }
      if (control2.touched && !value2) {
        control2Errors.required = true;
      } else if (value2) {
        delete control2Errors.required;
      }
      control1.setErrors(Object.keys(control1Errors).length ? control1Errors : null);
      control2.setErrors(Object.keys(control2Errors).length ? control2Errors : null);
    }
    return null;
  };
}

export const rangeValidator = (
  group: FormGroup
): ValidationErrors | null => {
  const valueControl = group.get('value');
  const maxControl = group.get('max');

  if (!valueControl || !maxControl) return null;

  const value = valueControl.value;
  const max = maxControl.value;

  if (!value) return null;

  if (value === max.toString() && !value.includes('+')) {
    valueControl.setErrors({ mustIncludePlus: true });
    return { mustIncludePlus: true };
  }

  if (value.includes('+')) {
    const plusCount = (value.match(/\+/g) || []).length;

    if (plusCount > 1 || !value.endsWith('+')) {
      valueControl.setErrors({ invalidRangeValue: true });
      return { invalidRangeValue: true };
    }

    const numericValue = +value.replace('+', '');
    if (numericValue !== +max) {
      valueControl.setErrors({ invalidRangeValue: true });
      return { invalidRangeValue: true };
    }
  }

  if (
    valueControl.hasError('mustIncludePlus') ||
    valueControl.hasError('invalidRangeValue')
  ) {
    valueControl.setErrors(null);
  }

  return null;
};

@Injectable({
  providedIn: 'root'
})
export class CorrectAnswerHelperService {

  correctAnswersForm: FormGroup<CorrectAnswersDetailsFormInterface>;

  isCorrectAnswerFormValid$ = new BehaviorSubject<boolean>(false);

  voidTypeOptions: { value: VoidType, label: string }[];

  constructor(
    private fb: NonNullableFormBuilder,
    private textService: TextService
  ) {
    this.voidTypeOptions = Object.values(VoidType)
      .map(value => {
        return {
          value,
          label: value === 'NO_RESULT' ? 'Question Void' : this.textService.createNameWithSpaceAndCapitalizeFirstLetter(value)
        }
      });
  }

  formProcessing(questionList: any) {
    const form = this.buildForm(questionList);
    this.detectChanges();
    return form;
  }
  buildForm(questionList: QuestionDetailsModel[]) {
    this.correctAnswersForm = this.fb.group({
      questionsForm: this.fb.array([this.createFormItem(questionList[0])]),
    });

    questionList.forEach((question, index) => {
      if (index > 0) {
        this.addFormControl(question);
      }
      if (!question.voidType) {
        this.addValidators(index, question.type);
      }
    })

    this.isCorrectAnswerFormValid$.next(this.isCorrectAnswersValid());

    return this.correctAnswersForm;
  }

  createFormItem(question: QuestionDetailsModel): FormGroup {
    const formGroup: FormGroup<SingleAnswersDetailsFormInterface> = this.fb.group({
      questionId: new FormControl(question.id),
      voidType: new FormControl(question.voidType),
      questionType: new FormControl(question.type),
    });

    switch (question.type) {
      case QuestionTypesEnum.SCORE:
      case QuestionTypesEnum.SCORE_PLUS:
        //eslint-disable-next-line
        const score = this.getScore(question.correctAnswer);
        formGroup.addControl('homeTeam', this.fb.control(score[0]));
        formGroup.addControl('awayTeam', this.fb.control(score[1]));
        return formGroup;
      case QuestionTypesEnum.RANGE:
        formGroup.addControl('value', this.fb.control(question.correctAnswer));
        formGroup.addControl('min', this.fb.control(question.attributes.minValue));
        formGroup.addControl('max', this.fb.control(question.attributes.maxValue));
        return formGroup;
      default:
        //set value -1 need for correct work void type
        //-1 is not available id so this is not broke regular logic
        if (question.correctAnswerId) {
          formGroup.addControl('answerId', this.fb.control(question.correctAnswerId));
        } else {
          formGroup.addControl('answerId', this.fb.control(-1));
        }
        return formGroup;
    }
  }

  get questionsForm(): FormArray {
    return this.correctAnswersForm.get('questionsForm') as FormArray;
  }

  addFormControl(question) {
    this.questionsForm.push(this.createFormItem(question));
  }

  getScore(score) {
    return score ? score.split(':') : ['', ''];
  }

  async detectChanges() {
    this.validateStatusChanges();
    await lastValueFrom(
      this.correctAnswersForm.valueChanges.pipe(
        startWith(this.correctAnswersForm.value),
        pairwise(),
        tap(([oldValue, newValue]) => {
          this.isCorrectAnswerFormValid$.next(this.isCorrectAnswersValid());
          newValue.questionsForm.forEach((value, index) => {
            if ((value.value || value.homeTeam || value.awayTeam || value.answerId) && value.voidType) {
              switch (value.questionType) {
                case QuestionTypesEnum.RANGE:
                  if (oldValue.questionsForm[index].voidType) {
                    this.updateFormValue('voidType', index, null);
                  } else {
                    this.updateFormValue('value', index, null);
                    this.removeValidator('value', index);
                    this.updateFormValue('voidType', index, value.voidType);
                  }
                  break;

                case QuestionTypesEnum.SCORE:
                case QuestionTypesEnum.SCORE_PLUS:
                  if (oldValue.questionsForm[index].voidType) {
                    this.updateFormValue('voidType', index, null);
                  } else {
                    this.updateFormValue('homeTeam', index, null);
                    this.updateFormValue('awayTeam', index, null);
                    this.removeValidator('homeTeam', index);
                    this.removeValidator('awayTeam', index);
                    this.updateFormValue('voidType', index, value.voidType);
                  }
                  break;

                default:
                  if (value.answerId > -1) {
                    if (oldValue.questionsForm[index].voidType) {
                      this.updateFormValue('voidType', index, null);
                      this.updateFormValue('answerId', index, value.answerId);
                    } else {
                      this.updateFormValue('answerId', index, null);
                      this.removeValidator('answerId', index);
                      this.updateFormValue('voidType', index, value.voidType);
                    }
                  }
                  break;
              }
            }
          })
        })
      )
    )

  }
  async validateStatusChanges() {
    await lastValueFrom(
      this.correctAnswersForm.statusChanges.pipe(
        tap(() => {
          return this.isCorrectAnswerFormValid$.next(this.isCorrectAnswersValid())
        })
      )
    )
  }
  isCorrectAnswersValid() {
    const atLeastOneValid = this.questionsForm.controls.some(control => {
      return ((control.valid || control.get('voidType').value))
    });
    const allDirtyValid = this.questionsForm.controls.every(control => {
      if (!control.dirty) return true;
      if (control.dirty) {
        return control.valid || control.get('voidType').value
      }
    });
    return allDirtyValid && atLeastOneValid;
  }
  updateFormValue(formControlName, index, value) {
    this.questionsForm.at(index).get(formControlName).patchValue(value);
  }
  addValidators(index, questionType) {
    switch (questionType) {
      case QuestionTypesEnum.RANGE:
        //eslint-disable-next-line
        const {min: minValue, max: maxValue} = this.questionsForm.at(index).value;
        this.questionsForm.at(index).get('value').addValidators([Validators.min(minValue), Validators.max(maxValue), Validators.pattern(/^[0-9+]+$/)]);
        this.questionsForm.at(index).setValidators(rangeValidator);
        break;
      case QuestionTypesEnum.SCORE:
        this.questionsForm.at(index).setValidators(validateControls('homeTeam', 'awayTeam'));
        this.questionsForm.at(index).get('homeTeam').addValidators([Validators.min(0), Validators.max(999), decimalOrNegativeValidator()]);
        this.questionsForm.at(index).get('awayTeam').addValidators([Validators.min(0), Validators.max(999), decimalOrNegativeValidator()]);
        break;
      case QuestionTypesEnum.SCORE_PLUS:
        this.questionsForm.at(index).setValidators(validateControls('homeTeam', 'awayTeam'));
        break;
    }
  }
  removeValidator(formControlName, index) {
    this.questionsForm.at(index).get(formControlName).clearValidators()
    this.questionsForm.at(index).get(formControlName).updateValueAndValidity()
  }

  getDifficultyLevel(question): StreakQuestionDifficultyEnum | null {
    if ('difficultyLevel' in question) {
      return (question as StreakQuestionListItemModel).difficultyLevel;
    }
    return null;
  }

  getIsMatch(question): boolean | null {
    if ('isMatched' in question) {
      return (question as QuestionDetailsModel).isMatched;
    }
    return null;
  }

  getVoidType(question): VoidType | null {
    if ('voidType' in question) {
      return (question as QuestionDetailsModel).voidType;
    }
    return null;
  }

}
