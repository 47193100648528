<div  class="round-wizzard-container scroll-container" cdkScrollable (scroll)="onBodyScroll($event)">

<round-wizzard-header
      (saveDraft)="onSaveDraft()"
      (previewAndPublish)="onPreviewAndPublish()"
      (editRoundImage)="editRoundImage()"
      (backButtonClicked)="backButtonClicked()"
      (restartResultProcessing)="restartResultProcessing()"
      (submitCorrectAnswers)="onSubmitCorrectAnswers()">
</round-wizzard-header>
  <ng-container  *ngIf="!(roundProcessingService.isLoading$ | async)">
    <ng-container [formGroup]="roundDetailsForm">
      <details-section
        (onOpenEditCloseDate)="onOpenEditCloseDate()"
        (onOpenEditBonus)="onOpenEditBonus()"
        [id]="tabIds[tabsEnum.DETAILS]">
      </details-section>
    </ng-container>

    <ng-container *ngIf="displayPredictionPrizeSection$ | async">
      <ng-container [formGroup]="predictionPrizeForm">
        <prediction-prize-section
          [displayPrizeSumControl]="roundDetailsForm.get('displayPrizeSum')"
          [id]="tabIds[tabsEnum.PRIZE]">
        </prediction-prize-section>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="displayScoreSection$ | async">
      <ng-container [formGroup]="eventForm">
        <event-score-section
          [id]="tabIds[tabsEnum.SCORE]"
          [roundEvent]="roundHelperService?.sportEvent$ | async">
        </event-score-section>
      </ng-container>
    </ng-container>

    <wizzard-massage *ngIf="roundHelperService?.isShowCloseRoundMassage$ | async">
      This game has now ended, please result it by selecting which were the correct answers below.
    </wizzard-massage>
    <ng-container>
      <ng-container *ngIf="(roundHelperService?.isFinishedRound$ | async); else createQuestion">
        <ng-container
          *ngIf="(questionList$ | async) as questionList"
          [formGroup]="correctAnswersForm">
          <correct-answers-prediction-section
            [voidTypeOptions]="correctAnswerPredictionServiceService.voidTypeOptions"
            [id]="tabIds[tabsEnum.QUESTIONS]"
            [questions]="questionList"
          >
          </correct-answers-prediction-section>
        </ng-container>
      </ng-container>
      <ng-template #createQuestion>
        <ng-container [formGroup]="predictionQuestionForm"
                      *ngIf="localizationService.vendorLocales$ | async as locales">
          <prediction-questions-section
            [locales]="locales"
            [questionOptions]="questionOptions$ | async"
            [id]="tabIds[tabsEnum.QUESTIONS]">
          </prediction-questions-section>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="roundProcessingService.isLoading$ | async" class="disabled-overlay">
  <mat-spinner [diameter]="30" color="primary"></mat-spinner>
</div>
