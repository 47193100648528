import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UriConfig} from "../../app.config";
import { Observable} from "rxjs";
import { QuestionDetailsModel } from "../../common/models/QuestionDetailsModel";
import { CreateQuestionDetailModel } from 'src/app/common/models/CreateQuestionDetailsModel';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) { }

  getQuestionList(roundId: number): Observable<QuestionDetailsModel[]> {
    return this.http.get<QuestionDetailsModel[]>(this.uriConfig.questionsList + '/' + roundId);
  }

  createNewQuestion(question: CreateQuestionDetailModel): Observable<QuestionDetailsModel> {
    return this.http.post<any>(this.uriConfig.questions, question);
  }

  updateQuestion(questionId: number, question: Partial<CreateQuestionDetailModel>): Observable<QuestionDetailsModel> {
    return this.http.patch<QuestionDetailsModel>(this.uriConfig.questions + '/' + questionId, question);
  }
}
