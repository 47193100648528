import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { UriConfig } from "@app/app.config";
import { QuestionModel } from "@models/QuestionModel";
import { QuestionTypesEnum } from "@enums/QuestionTypesEnum";
import { BehaviorSubject, Observable } from "rxjs";
import { StreakQuestionListItemModel } from "@models/StreakQuestionListItemModel";
import { SelectOption } from "@components/_base-component/select/select.component";
import { LocaleItem } from "@models/LocalesResponseModel";

@Injectable({
  providedIn: 'root'
})
export class QuestionsBankService {

  needUpdateQuestions$ = new BehaviorSubject(true);

  questionsBank$ = new BehaviorSubject([]);

  externalAnswerOptions = ['AWAY_TEAM', 'HOME_TEAM', 'DRAW'];

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
  ) {}

  createQuestion(question, questionType, localeizationData, locales, answerGroups, isStreak = false) {
    const questionBody = this.createQuestionBody(question, questionType, localeizationData, locales, answerGroups, isStreak);
    if (isStreak) {
      return this.http.post<QuestionModel>(this.uriConfig.questionsStreak, questionBody);
    } else {
      return this.http.post<QuestionModel>(this.uriConfig.questions, questionBody);
    }
  }

  updateQuestion(question, questionType, localeizationData, locales, answerGroups, id,  isStreak = false) {
    const questionBody = this.createQuestionBody(question, questionType, localeizationData, locales, answerGroups, isStreak);
    if (isStreak) {
      return this.http.patch<QuestionModel>(this.uriConfig.questions + '/' + id + '/streak', questionBody);
    } else {
      return this.http.patch<QuestionModel>(this.uriConfig.questions + '/' + id, questionBody);
    }
  }

  getQuestionById(id: number, isStreak = false): Observable<QuestionModel>{
    return this.http.get<QuestionModel>(this.uriConfig.questions + '/' + id + (isStreak ? '/streak' : ''));
  }

  getLocalizations(localizationData, locales, answersKey?, index?) {
    return Object.keys(localizationData).map(locale => {
      return {
        localeId: locales.find(item => item.country === locale).id,
        text: answersKey ? localizationData[locale][answersKey][index].text : localizationData[locale].text
      }
    })
  }

  getLocalizedAnswers(groupId, answers, localeizationData, locales, answerGroups, isStreak) {
    const groupName = answerGroups.find(item => item.id === groupId);
    return answers.map((answer, index) => {
      const resultAnswer = {
        answerId: answer.id,
        groupName: groupName ? groupName.name : null,
        localizations: this.getLocalizations(localeizationData, locales, 'answers', index)
      }
      if (!isStreak) {
        resultAnswer['answerGroupId'] = groupId ? groupId : null;

        if (this.externalAnswerOptions.includes(answer.text)) {
          delete resultAnswer.localizations;
        }
      }
      return resultAnswer;
    });
  }

  getLocalizedAnswersForLists(groupId1, groupId2, answers1st, answers2st, localeizationData, locales, answerGroups, isStreak) {
    const groupName1 = answerGroups.find(item => item.id === groupId1);
    const groupName2 = answerGroups.find(item => item.id === groupId2);

    const answers1 = answers1st.map((answer, index) => {
      const resultAnswer = {
        answerId: answer.id,
        groupName: groupName1 ? groupName1.name : 'group_0',
        localizations: this.getLocalizations(localeizationData, locales,'answers1st', index)
      }
      if (!isStreak && groupId1) {
        resultAnswer['answerGroupId'] = groupId1;
      }
      return resultAnswer;
    });

    const answers2 = answers2st.map((answer, index) => {
      const resultAnswer = {
        answerId: answer.id,
        groupName: groupName2 ? groupName2.name : 'group_1',
        localizations: this.getLocalizations(localeizationData, locales, 'answers2st', index)
      }

      if (!isStreak && groupId2) {
        resultAnswer['answerGroupId'] = groupId2;
      }

      return resultAnswer;
    });

    return [...answers1, ...answers2]
  }


  createQuestionBody(question, questionType, localeizationData, locales, answerGroups, isStreak) {
    const type = question.type;

    let questionBody: Partial<QuestionModel> = {
      type: type,
      text: question.text,
      imageUrl: question.imageUrl ? question.imageUrl : null,
      difficultyLevel: question.difficultyLevel,
      localizations: this.getLocalizations(localeizationData, locales)
    }

    if (!isStreak) {
      questionBody.isForPredictionBank = true;
      delete questionBody.difficultyLevel;

      if (questionType.groupId
        && (type === QuestionTypesEnum.LIST || type === QuestionTypesEnum.GRID || type === QuestionTypesEnum.OPTIONS)) {
        questionBody.answerGroupId = questionType.groupId;
      }

      if (type === QuestionTypesEnum.LISTS) {
        questionBody.answerGroupId = questionType.answerGroupId ? questionType.answerGroupId : null;
        questionBody.answerGroupId2 = questionType.answerGroupId2 ? questionType.answerGroupId2 : null;
      }

    }



    switch (type) {
      case QuestionTypesEnum.SCORE_PLUS:
        questionBody = {
          ...questionBody,
          maxScoreValue: questionType.maxScoreValue + '+'
        }
        break;
      case QuestionTypesEnum.RANGE:
        questionBody = {
          ...questionBody,
          attributes: {
            minValue: questionType.minValue,
            maxValue: questionType.maxValue,
            incrementValue: questionType.incrementValue,
            displayValue: questionType.displayValue
          }
        }
        break;
      case QuestionTypesEnum.OPTIONS:
      case QuestionTypesEnum.LIST:
      case QuestionTypesEnum.GRID:
        questionBody = {
          ...questionBody,
          answers: this.getLocalizedAnswers(questionType.groupId, questionType.additional.answers, localeizationData, locales, answerGroups, isStreak)
        }
        break;
      case QuestionTypesEnum.LISTS:
        questionBody = {
          ...questionBody,
          answers: this.getLocalizedAnswersForLists(
            questionType.answerGroupId,
            questionType.answerGroupId2,
            questionType.additional.answers1st,
            questionType.additional.answers2st,
            localeizationData,
            locales,
            answerGroups,
            isStreak
          )
        }
        break;

    }

    return questionBody
  }

  fetchQuestionList(requestBody, isStreak = false) {
    let params = new HttpParams()
    Object.keys(requestBody).forEach(param => {
      if (param === "difficultyLevels") {
        requestBody[param]?.forEach(level => {
          params = params.append(param, level);
        })
      } else {
        params = params.set(param, requestBody[param]);
      }
    })
    return this.http.get<{records: {[key:string] : string}[], total: number }>(this.uriConfig.baseApiUrl + 'questions' + (isStreak ? '/streak' : '/prediction'), { params });
  }

  deleteQuestion(questionId) {
    return this.http.delete(this.uriConfig.questions + '/' +questionId);
  }

  fetchQuestionListByRoundId(roundId): Observable<any> {
    return this.http.get<StreakQuestionListItemModel[]>(this.uriConfig.questionsStreak + '/' + roundId)
  }

  toggleQuestionStatus(questionId: number, isDisabled: boolean) {
    return this.http.patch<QuestionModel>(
      `${this.uriConfig.questions}/${questionId}/streak`,
      { isDisabled }
    );
  }

  getQuestionTypeLabel(type: QuestionTypesEnum) {
    switch (type) {
      case QuestionTypesEnum.SCORE:
        return 'Correct score format';
      case QuestionTypesEnum.SCORE_PLUS:
        return 'Custom Score';
      case QuestionTypesEnum.RANGE:
        return 'Select number from slider';
      case QuestionTypesEnum.OPTIONS:
        return 'Select from 2 answers option';
      case QuestionTypesEnum.LIST:
        return '1 from 1 list';
      case QuestionTypesEnum.LISTS:
        return '1 from 2 list';
      case QuestionTypesEnum.GRID:
        return '1 from grid';
      default:
        return 'Correct score format';
    }
  }

  transformQuestionForSelect(question): SelectOption {
    return ({label: `ID: ${question.id} Text: ${question.text} Type: ${this.getQuestionTypeLabel(question.type)}`, value: question.id})
  }

  transformLocalizations(localizations: LocaleItem[], locales) {
    return localizations.map(locale =>
      ({
        text: locale?.text,
        localeId: locales.find(item => item.i18n === locale?.locale?.i18n).id }
      ))
  }

  isListQuestionType(type: QuestionTypesEnum) {
    return type === QuestionTypesEnum.LISTS
      || type === QuestionTypesEnum.OPTIONS
      || type === QuestionTypesEnum.GRID
      || type === QuestionTypesEnum.LIST;
  }
}
