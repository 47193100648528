export enum HeaderButtonsEnum {
  ALL_GAMES = 'all-games',
  CUSTOM_LEADERBOARD = 'custom-leaderboards',
  VENDORS = 'vendors',
  LANGUAGES = 'languages',
  QUESTIONS = 'questions',
  STREAK_DASHBOARD = 'streak-dashboard',
  STREAK_CUSTOM_LEADERBOARDS = 'streak-custom-leaderboards',
  UPSELL = 'upsell',
  WHITELISTED_USERS = 'whitelisted-users'
}
