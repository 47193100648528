<div class="app-header-container">
  <div class="page-info" *ngIf="(userDetails$ | async) as userDetails">
    <div class="chapter-mame body-medium-regular" *ngIf="currentChapter">{{currentChapter}}</div>
    <div class="page-name-wrapper">
      <ng-container
        *ngIf="(userDetails.brand?.name || userDetails.brand?.imageUrl); else onlyPageName">
        <h2 class="page-mame">
          <span>{{currentPage}}</span>
          <span>{{' - '}}</span>
          <span>{{userDetails.brand.name}}</span>
          <img class="brand-image" [src]="userDetails.brand.imageUrl">
        </h2>
      </ng-container>
    </div>
    <ng-template #onlyPageName>
      <h2 class="page-mame">{{currentPage}}</h2>
    </ng-template>
  </div>
  <div class="buttons-container page-buttons">
    <button *ngIf="!isAdmin" mat-stroked-button color="primary" (click)="openHelpPopup()">
      Help
    </button>
    <ng-container [ngSwitch]="currentLocation">
      <ng-container *ngSwitchCase="headerButtonsEnum.ALL_GAMES">
        <button mat-raised-button color="primary" (click)="downloadDiffReport()">
          Download a difference report
        </button>
        <button mat-raised-button color="primary" (click)="downloadAnalyticsReport()">
          Report
      </button>
        <button mat-raised-button color="primary" (click)="createGame()">
          Create Game
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="headerButtonsEnum.QUESTIONS">
        <button mat-raised-button color="primary" (click)="createQuestion()">
          Create Question
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="headerButtonsEnum.UPSELL">
        <button mat-raised-button color="primary" (click)="createUpsell()">
          Create a new banner
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="headerButtonsEnum.WHITELISTED_USERS">
        <button mat-raised-button color="primary" (click)="createWhitelistedUser()">
          Add new IP Address
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="headerButtonsEnum.CUSTOM_LEADERBOARD">
        <ng-template [ngTemplateOutlet]="leaderboardNotificationButtons"></ng-template>
        <button mat-raised-button color="primary"  *ngIf="(profileService.currentUser$ | async).isStreakVendor; else predictionButton" (click)="addStreakLeaderboard()">
          Create a custom leaderboard
        </button>
        <ng-template #predictionButton>
          <button mat-raised-button color="primary" (click)="addLeaderbord()">
            Create a custom leaderboard
          </button>
        </ng-template>
      </ng-container>



      <ng-container *ngSwitchCase="headerButtonsEnum.VENDORS">
        <button
          mat-stroked-button
          color="primary"
          *ngIf="(adminsService.maintenanceStatus$ | async) as maintenanceStatus"
          (click)="onChangeMaintenance(maintenanceStatus.maintenanceModeEnabled)">
          {{ maintenanceStatus.maintenanceModeEnabled ?
            'Disable maintenance mode' :
            'Enable maintenance mode' }}
        </button>
        <button mat-stroked-button color="primary" (click)="onClearCache()">
          Clear cache
        </button>
        <button mat-raised-button color="primary" (click)="addNewVendor()">
          New Vendor
        </button>
      </ng-container>

      <button mat-raised-button color="primary" *ngSwitchCase="headerButtonsEnum.LANGUAGES"
              (click)="addNewLanguage()">
        Add Language
      </button>
      <ng-container *ngSwitchCase="HeaderButtonsEnum.STREAK_DASHBOARD">
        <button mat-raised-button color="primary" (click)="createStreakGame()">
          Create Streak
        </button>
      </ng-container>
    </ng-container>
  </div>
  <ng-template #leaderboardNotificationButtons>
    <button mat-raised-button color="primary" (click)="toggleLeaderboardNotification(true)">
      Notify users
    </button>
    <button mat-raised-button color="primary" (click)="toggleLeaderboardNotification(false)">
      Hide notification
    </button>
  </ng-template>
</div>
