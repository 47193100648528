<div class="image-container">
  <img
    class="item-image"
    (load)="onImageLoaded()"
    [ngClass]="getClassName()"
    [src]="imageSrc">
  <div
    *ngIf="!isImageLoading"
    class="disabled-overlay image"
    [ngClass]="getOverlayClassName()"
    [style.height]="overlayHeight"
    [style.width]="fixedOverleyWidth">
    <mat-spinner [diameter]="spinnerSize" color="primary"></mat-spinner>
  </div>
</div>
<mat-icon
  *ngIf="showFlipHorizontally"
  class="material-icons material-icons-outlined"
  (click)="flipHorizontally()">
  flip
</mat-icon>

