import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, catchError, combineLatest, of, Subject, take, throwError } from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogService } from "../../_base-component/dialog/dialog.service";
import { filter, switchMap, takeUntil, tap } from "rxjs/operators";
import { InfoPopupCmsService } from "@services/info-popup-cms.service";
import { InfoPopupTabsModel } from "@models/InfoPopupTabsModel";
import { APP_DATA } from "@app/general.app.config";
import { SnackBarService } from "@services/snack-bar.service";
import { InfoPopupTabEnum } from "@enums/InfoPopupTabEnum";
import { RoundService } from "@services/round.service";
import { RoundDetailsModel } from "@models/RoundDetailsModel";
import { SaasSettingsService } from "@services/saas-settings.service";
import { isEmpty, isNull } from "lodash";

@Component({
  selector: 'edit-prize-popup',
  templateUrl: './edit-prize-popup.component.html',
  styleUrls: ['./edit-prize-popup.component.scss']
})
export class EditPrizePopupComponent implements OnInit, OnDestroy{
  locales = [];

  formGroup: FormGroup;

  selectedTabIndex = 0;

  currentTab: InfoPopupTabsModel;

  linkRegex = /<a\s+[^>]*href\s*=\s*['"]([^'"]*)['"][^>]*>(.*?)<\/a>/gi;

  linkRegexBroken = /<a\s+href=\\"([^\\]*)\\"\s+title=\\"([^\\]*)\\"\s+target=\\"([^\\]*)\\">([^<]*)<\/a>/gi;
  rounds= []

  isLoaded$ = new BehaviorSubject(true);

  prizes: {[key: string]: number | string} = {};

  round: RoundDetailsModel;
  protected readonly appData = APP_DATA;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<EditPrizePopupComponent>,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private infoPopupCmsService: InfoPopupCmsService,
    private roundService: RoundService,
    private sassSettings: SaasSettingsService
  ) {
    this.formGroup = this.fb.group({});
    this.locales = this.data.locales.length ? [{country: 'Default', i18n: ''}, ...this.data.locales] : [{country: 'Default', i18n: ''}];

  }

  ngOnInit(): void {
    this.createForms(this.locales);
    this.isLoaded$.next(false);
    combineLatest([
      this.infoPopupCmsService.getRoundPrizePopup(this.data.round.id),
      this.roundService.getRoundById(this.data.round.id)
    ]).pipe(
      takeUntil(this.unsubscribe$),
      catchError((error) => {
        this.isLoaded$.next(true);
        this.snackBarService.showSnackBar(error.error, true);
        return throwError(error);
      })
    ).subscribe(([tab, round]: [InfoPopupTabsModel, RoundDetailsModel]) => {
      this.round = round;
      this.prizes = round.prize;
      this.isLoaded$.next(true);
      if(!tab || !tab.content.content.content) {
        this.infoPopupCmsService.getDefaultRoundModal(this.locales, this.round).subscribe(res => {
          Object.keys(res).forEach(key => {
            this.formGroup.get(key).patchValue({
              title: res[key].title,
              content: res[key].content,
              name: res[key].title
            });
          });
        });
      } else {
        this.currentTab = tab;
        this.initForm();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm() {
    this.formGroup.get('Default').patchValue({
      title: this.currentTab.title.title,
      name: this.currentTab.name.name,
    });

    if (this.currentTab.content.content.content) {
      this.formGroup.get('Default').patchValue({
        content: this.currentTab.content.content.content
      });
    }

    this.data.locales.forEach((locale) => {
      const targetLocalizationName = this.currentTab.name.localizations.find(item => item.locale.i18n === locale.i18n);
      const targetLocalizationTitle = this.currentTab.title.localizations.find(item => item.locale.i18n === locale.i18n);

      this.formGroup.get(locale.country).get('name').patchValue(targetLocalizationName.text);
      this.formGroup.get(locale.country).get('title').patchValue(targetLocalizationTitle.text);

      if (this.currentTab.content.content.content) {
        const targetLocalizationContent = this.currentTab.content.localizations.find(item => item.locale.i18n === locale.i18n);
        const contentToParse = targetLocalizationContent.text;
        const parsedContent = JSON.parse(contentToParse);
        this.formGroup.get(locale.country).patchValue({
          content: parsedContent.content
        });
      }
    })
  }

  createForms(locales: { country: string }[]): void {
    locales.forEach(locale => {
      this.formGroup.addControl(locale.country, this.createForm());
    });

  }

  createForm(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      title: ['', Validators.required],
      content: ['', Validators.required],
    });
  }

  onSubmit(): void {
    const formValue = {...this.formGroup.get('Default').value}

    const defaultValue = {
      ...formValue,
      type: InfoPopupTabEnum.PRIZE,
      name: `${this.formGroup.get('Default').get('title').value} ${this.data.round.id}`,
      roundId: this.data.round.id,
      title: this.formGroup.get('Default').get('title').value,
      content: this.createDataDefault('Default')
    };
    const targetRequest$ = this.currentTab ? this.infoPopupCmsService.updateInfoPopupTab(defaultValue, this.currentTab.id)
      : this.infoPopupCmsService.addInfoPopupTab(defaultValue);

    const request$ = targetRequest$.pipe(
      takeUntil(this.unsubscribe$),
      switchMap(() => this.currentTab ? of(this.currentTab) : this.infoPopupCmsService.getRoundPrizePopup(this.data.round.id)),
      switchMap(data => {
        const localizationResult = this.getLocalizationBody(defaultValue, this.currentTab ? this.currentTab : data);
        return this.infoPopupCmsService.localizeTabs(localizationResult);
      }),
      tap(() => this.infoPopupCmsService.needUpdateContent$.next(true)),
      tap(data => {
        this.snackBarService.showSnackBar(`The tab was edited  successfully!`)
        this.currentTab = data;
        this.dialog.closeAll();
      }),
      catchError((error) => {
        this.snackBarService.showSnackBar(error.error, true);
        return throwError(error);
      })
    );

    if (!this.checkIfFormsValid()) {
      this.dialogService.open(
        {
          dialogContent: 'Some data is not updated for other locales. Click “Save” to use the info from default locale for other locales where the data is missing',
          labelOk: 'Save',
          labelNo: 'Close'
        }
      ).pipe(
        takeUntil(this.unsubscribe$),
        switchMap(data => {
          if (data) {
            return request$;
          } else {
            return of(true);
          }
        })
      ).subscribe()
    } else {
      request$.subscribe();
    }
  }

  cleanPastedContent(html) {
    html = html.replace(/\\&quot;/g, '');
    html = html.replace(/\\"/g, '"');
    return html;
  }

  cleanUnsafeLinks(htmlString: string) {
    if(htmlString) {
      const resultStepOne =  htmlString.replace(this.linkRegex, (match) => {
        return this.cleanPastedContent(match);
      });
      return resultStepOne.replace(this.linkRegexBroken, (match) => {
        return this.cleanPastedContent(match);
      });
    }
  }

  updatePrizeAutomatically() {
    const currencyAfterPrice = this.sassSettings.getCurrencyAfterPriceSettings()?.value;
      this.infoPopupCmsService.getPrizeDetails(this.prizes, this.locales, currencyAfterPrice,this.round.questions).pipe(take(1)).subscribe(prizeTemplate => {
      Object.keys(prizeTemplate).forEach(key => {
        const localeContentControl = this.formGroup.get(key).get('content')
        let localeTemplate = localeContentControl.value;
        if(localeTemplate.match(/<ul>(.*?)<\/ul>/g)?.length ){
          localeTemplate = localeTemplate.replace(/<ul>(.*?)<\/ul>/g, `${prizeTemplate[key].content}`);
          localeContentControl.patchValue(localeTemplate)
        }
        else {
          localeContentControl.patchValue(localeContentControl.value.trimEnd() + `${prizeTemplate[key].content}`)
        }
      })

    })
  }


  getLocalizationBody(defaultValue: any, data: any) {
    let result = [];
    this.data.locales.forEach((locale) => {
      const value = {
        ...this.formGroup.get(locale.country).value,
        content: this.createDataDefault(locale.country),
      };

      let contentDataHTML = '';

      contentDataHTML = value.content.content ? this.cleanUnsafeLinks(value.content.content) : defaultValue.content.content;

      const htmlString = JSON.stringify({content: contentDataHTML});

      const targetContentDataHTML = this.cleanUnsafeLinks('{' + htmlString + '}').slice(1, -1);

      const targetLocalizationContent = data.content.localizations.find(item => item.locale.i18n === locale.i18n);

      const content = {
        text: targetContentDataHTML,
        id: targetLocalizationContent.id
      }

      const targetLocalizationName = data.title.localizations.find(item => item.locale.i18n === locale.i18n);

      const name = {
        text: value.name ? value.name : defaultValue.name,
        id: targetLocalizationName.id
      }

      const targetLocalizationTitle = data.title.localizations.find(item => item.locale.i18n === locale.i18n);


      const title = {
        text: value.title ? value.title : defaultValue.title,
        id: targetLocalizationTitle.id
      }

      result = [...result, content, name, title]
    });

    return result;
  }

  checkIfFormsValid() {
    let flag = false;
    if (!this.data.locales.length) {
      flag = this.formGroup.get('Default').get('title').valid;
    } else {
      this.data.locales.forEach(locale => {
        if (!flag) {
          flag = this.formGroup.get(locale.country).get('title').valid;
        }
      });
    }
    return flag;
  }

  createDataDefault(locale: string) {
      return {
        content: this.formGroup.get(locale).value.content ? this.cleanUnsafeLinks(this.formGroup.get(locale).value.content) : null
      };
  }


  close() {
    const isDirty = (!this.formGroup.dirty)
    if (isDirty) {
      this.dialog.closeAll();
    } else {
      this.dialogService.open(
        {
          dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
          labelOk: 'Yes',
          labelNo: 'No'
        }
      ).pipe(
        takeUntil(this.unsubscribe$),
        filter(response => !!response),
        tap((response) => {
          if (response) {
            this.dialog.closeAll();
          }
        })
      ).subscribe();
    }
  }

  changeTabIndex(event: any) {
    this.selectedTabIndex = event.index;
  }

  getContentControl(locale: string) {
    return this.formGroup.get(locale).get('content') as FormControl;
  }

  isFormDisabled() {
    return  !this.formGroup.get('Default').valid
  }

  protected readonly length = length;
  protected readonly isEmpty = isEmpty;
  protected readonly isNull = isNull;
}
