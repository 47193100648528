import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UriConfig } from "@app/app.config";
import { BehaviorSubject, catchError, combineLatest, map, Observable, of } from "rxjs";
import {
  InfoPopupBreakdownData,
  InfoPopupTabsCreateModel,
  InfoPopupTabsEditResponseModel,
  InfoPopupTabsModel,
  InfoTabLocalized
} from "@models/InfoPopupTabsModel";
import { ProfileService } from "@services/profile.service";
import { CmsService } from "@services/cms.service";
import { SaasSettingsService } from "@services/saas-settings.service";
import { RoundPrizeTypeEnum } from "@enums/RoundPrizeTypeEnum";
import { RoundDetailsModel } from "@models/RoundDetailsModel";

@Injectable({
  providedIn: 'root'
})
export class InfoPopupCmsService {
  needUpdateContent$ = new BehaviorSubject(true);

  constructor(
    protected http: HttpClient,
    private uriConfig: UriConfig,
    private  profileService: ProfileService,
    private cmsService: CmsService,
    private sassSettings: SaasSettingsService,
  ) {}

  getInfoPopupTabs(): Observable<InfoPopupTabsModel[]> {
    return this.http.get<InfoPopupTabsModel[]>(this.uriConfig.infoPopupTabs);
  }

  addInfoPopupTab(tab: InfoPopupTabsCreateModel): Observable<any> {
    return this.http.post<InfoPopupTabsCreateModel>(this.uriConfig.infoPopupTabs, tab);
  }

  localizeTabs(tabs: InfoTabLocalized[]): Observable<any> {
    const body = {
      items: [...tabs]
    }
    return this.http.patch<InfoPopupTabsCreateModel>(this.uriConfig.localizations, body);
  }


  updateInfoPopupTab(tab: InfoPopupTabsCreateModel, id: number): Observable<InfoPopupTabsEditResponseModel[]> {
    return this.http.patch<InfoPopupTabsEditResponseModel[]>(this.uriConfig.infoPopupTabs + '/' + id, tab);
  }

  deleteInfoPopupTab(id: number): Observable<InfoPopupTabsEditResponseModel[]> {
    return this.http.delete<InfoPopupTabsEditResponseModel[]>(this.uriConfig.infoPopupTabs + '/' + id);
  }

  displayPrizeModalOnLanding(id: number): Observable<any> {
    return  this.http.patch(this.uriConfig.rounds + `/${id}/winnings-displaying`, {})
  }

  getPrizeModalB2CData(): Observable<InfoPopupBreakdownData> {
    const {domain} = this.profileService.currentUser$.value;
    let query = 3;
    if(domain.includes('napoleon')) {
      query = 2
    } else if(domain.includes('romania')) {
      query = 4
    }
    return this.http.get<InfoPopupBreakdownData>(this.uriConfig.infoPopupTabs + `/b2c/breakdown-winnings?minValue=${query}`)
  }

  getRoundsBreakdownList(): Observable<InfoPopupBreakdownData[]> {
    const {domain} = this.profileService.currentUser$.value;
    let query =  3;
    if(domain.includes('napoleon')) {
      query = 2
    } else if(domain.includes('romania')) {
      query = 4
    }
    return this.http.get<InfoPopupBreakdownData[]>(this.uriConfig.infoPopupTabs + `/breakdown-winnings?minValue=${query}`)
  }

  showOnLandingFeatureInfoModal() {
    return this.http.patch<InfoPopupBreakdownData[]>(this.uriConfig.infoPopupTabs + `/mark-unseen`, {})
  }

  getRoundPrizePopup(id: number): Observable<any> {
    return this.http.get<InfoPopupTabsModel>(this.uriConfig.infoPopupTabs + `/prize-popups/${id.toString()}`)
  }

  getDefaultRoundModal(locales: { country: string, i18n: string }[], round: RoundDetailsModel): Observable<any> {
    const currencyAfterPrice = this.sassSettings.getCurrencyAfterPriceSettings()?.value;
    const isCashPoints = round.prizeType === RoundPrizeTypeEnum.CASH_POINTS;

    return combineLatest([
      this.fetchTextLabel('iframe-v3-prize-modal-header'),
      this.fetchTextLabel('iframe-v3-prize-modal-description'),
      this.fetchTextLabel('iframe-v3-amount-landing'),
      this.fetchTextLabel('iframe-v3-prize-modal-amount-description'),
      this.fetchTextLabel('iframe-v3-prize-modal-label-description'),
      this.fetchTextLabel('iframe-v3-currency'),
      this.getPrizeDetails(round.prize, locales, currencyAfterPrice, round.questions),
    ]).pipe(
      map(([title, content, amountValue, amountDescription, labelDescription, currency, prizeDetails]) => {
        return locales.reduce((acc, locale) => {
          let finalContent = '';

          if (isCashPoints) {
            const prizeAmount = round.prizeTotal || (!locale.i18n ? amountValue['Text value'] : this.cmsService.getLocalizedLable(amountValue, locale));
            let amount = ''
            if(currency){
            amount = currencyAfterPrice
              ? `${prizeAmount}${ !locale.i18n ? currency['Text value'] : this.cmsService.getLocalizedLable(currency, locale)}`
              : `${!locale.i18n ? currency['Text value'] : this.cmsService.getLocalizedLable(currency, locale)}${prizeAmount}`;
          }

            finalContent += `<p>${!locale.i18n
              ? content['Text value'].replace('{maxPrize}', `<strong>${amount}</strong>`)
              : this.cmsService.getLocalizedLable(content, locale)?.replace('{maxPrize}', `<strong>${amount}</strong>`)}</p>`;
          } else {
            finalContent += `<p>${!locale.i18n ? labelDescription['Text value'] : this.cmsService.getLocalizedLable(labelDescription, locale)}</p>`;
          }

          finalContent += `<p>${!locale.i18n
            ? amountDescription['Text value']
            : this.cmsService.getLocalizedLable(amountDescription, locale)}</p>`;

          if(prizeDetails && prizeDetails[locale.country]) {
            finalContent += `${prizeDetails[locale.country].content}`;
          }

          acc[locale.country] = {
            content: finalContent,
            title: !locale.i18n ? title['Text value'] : this.cmsService.getLocalizedLable(title, locale),
          };
          return acc;
        }, {});
      })
    );
  }

  getPrizeDetails(prizes: any = {}, locales: any[], currencyAfterPrice: boolean, questions): Observable<any> {
    return combineLatest([
      this.fetchTextLabel('iframe-v3-currency'),
      this.fetchTextLabel('iframe-v3-prize-modal-shared-prize-description')
    ]).pipe(
      map(([currency, splitText]) => {
        if(prizes) {
          const prizeList = Object.keys(prizes).map(correctCount => {
          const currentPrize = prizes[correctCount];
          const {assignment, reward, type} = currentPrize;
          const prizeAmount =
            typeof reward === 'string'
              ? reward
              : this.formatThousands(reward, '.').toString();
          return { correctCount, assignment, prize: prizeAmount, type };
        });

        return locales.reduce((acc, locale) => {
          const localizedCurrency = !locale.i18n
            ? currency['Text value']
            : this.cmsService.getLocalizedLable(currency, locale);

          const prizeDetails = prizeList?.map(prize => {
           let  prizeText = prize.assignment === 'SPLIT' && !!splitText ? `<li style="list-style: none">${!locale.i18n ? splitText['Text value']
             : this.cmsService.getLocalizedLable(splitText, locale)}</li>` : '';

             prizeText = prizeText + (prize.type === RoundPrizeTypeEnum.CASH_POINTS ?
               `<li><strong>${currencyAfterPrice ? `${prize.prize}${localizedCurrency}` : `${localizedCurrency}${prize.prize}`}</strong>  – ${prize.correctCount}/${questions?.length} </li>`:
               `<li><strong>${prize.prize}</strong>  – ${prize.correctCount}/${questions?.length} </li>`);

            return {
              correctCount: prize.correctCount,
              text: `${prizeText}`,
            };
          });

          const prizeTemplate = prizeDetails
            .map(prize => `${prize.text}`)
            .join('');

          acc[locale.country] = {}
          acc[locale.country]['content'] = `<ul>${prizeTemplate}</ul>`;
          return acc;
        }, {});
        }
      })
    );
  }

  fetchTextLabel(name: string) {
    return this.cmsService.getTextLabels({ name }).pipe(
      map(res => res?.records?.find(record => record.Name === name && !!record._state) || null),
      catchError(() => {
        return of(null);
      })
    );
  }

  formatThousands(number, amountSeparator) {
    if (!number) return null;

    const numStr = number.toString();
    if (numStr.length <= 3) {
      return numStr;
    } else {
      const formattedSubstr = this.formatThousands(numStr.slice(0, -3), amountSeparator);
      return formattedSubstr + amountSeparator + numStr.slice(-3);
    }
  }
}

