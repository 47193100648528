import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  Subject,
  catchError,
  lastValueFrom,
  takeUntil,
  tap,
  throwError,
  switchMap, of, Observable, take
} from "rxjs";
import {CurrentLocationService} from "@services/current-location.service";
import {TextService} from "@services/text.service";
import {MatDialog} from "@angular/material/dialog";
import {CreateVendorModalComponent} from "../_common-modal-components/create-vendor-modal/create-vendor-modal.component";
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { AnalyticsService } from "@services/analytics.service";
import { AddNewLocaleComponent } from "../_common-modal-components/add-new-locale/add-new-locale.component";
import { DialogService } from "../_base-component/dialog/dialog.service";
import { AdminsService } from "@services/admins.service";
import { NewRoundTypeSelectModalComponent } from 'src/app/new-round-wizzard/new-round-type-select-modal/new-round-type-select-modal.component';
import { HeaderButtonsEnum } from "@enums/HeaderButtonsEnum";
import { UserDetailsModel } from "@models/UserDetailsModel";
import { ProfileService } from "@services/profile.service";
import { HelpPopupComponent } from "../_common-modal-components/help-popup/help-popup.component";
import { CreateStreakComponent } from "../_streak/create-streak/create-streak.component";
import { DiffReportComponent } from "../_common-modal-components/diff-report/diff-report.component";
import { filter } from "rxjs/operators";
import {
  SelectLeaderboardTypeModalComponent
} from "../_common-modal-components/create-leaderboard/select-leaderboard-type-modal/select-leaderboard-type-modal.component";
import {
  CreateUpsellBannerComponent
} from "../_common-modal-components/create-upsell-banner/create-upsell-banner.component";
import { CustomLeaderboardService } from "@services/custom-leaderboard.service";
import {
  CreateSpecialLeaderboardComponent
} from "@components/_common-modal-components/create-leaderboard/create-special-leaderboard/create-special-leaderboard.component";
import {
  CreateWhitelistedUserComponent
} from "@components/_common-modal-components/create-whitelisted-user/create-whitelisted-user.component";
import { CreateQuestionComponent } from "@components/_common-modal-components/create-question/create-question.component";

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  @Input() isAdmin: boolean;

  /**
   * Current page name
   */
  currentPage: string;

  /**
   * Current chanter name
   */
  currentChapter: string;

  currentLocation: string;

  headerButtonsEnum = HeaderButtonsEnum;

  userDetails$: Observable<UserDetailsModel> = this.profileService.currentUser$;

  private unsubscribe$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.currentLocationService.getCurrentChapterName()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(chapterName => {
        this.currentChapter = chapterName.length > 3 ? chapterName : chapterName.toUpperCase();
      })

    this.currentLocationService.getCurrentPage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(pagePath => {
        this.currentLocation = pagePath;
        const currentPage = this.textService.createNameWithSpaces(pagePath);

        this.currentPage = this.textService.capitalizeFirstLetterInEachWord(currentPage);
      })
  }

  constructor(
    private currentLocationService: CurrentLocationService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private analyticsService: AnalyticsService,
    private dialogService: DialogService,
    private customLeaderboardService: CustomLeaderboardService,
    public profileService: ProfileService,
    public adminsService: AdminsService,
    public textService: TextService,
  ) {

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createGame() {
    this.dialog.open(NewRoundTypeSelectModalComponent, {
      width: '660px',
      panelClass:['prize-builder'],
    })
  }

  createUpsell() {
    this.dialog.open(CreateUpsellBannerComponent, {
      maxHeight:'860px',
      width:'640px',
      height:'90%',
      panelClass:['prize-builder'],
      autoFocus: false
    })
  }

  createWhitelistedUser() {
    this.dialog.open(CreateWhitelistedUserComponent, {
      maxHeight:'450px',
      width:'640px',
      height:'90%',
      panelClass:['prize-builder'],
      autoFocus: false
    })
  }

  createQuestion() {
    this.dialog
      .open(CreateQuestionComponent,{
        maxHeight:'860px',
        width:'640px',
        height:'90%',
        panelClass:['prize-builder'],
        autoFocus: false
      })
  }

  openHelpPopup() {
    this.dialog.open(HelpPopupComponent, {
      maxHeight:'470px',
      width:'750px',
      height:'90%',
      panelClass:['prize-builder'],
    })
  }

  async downloadAnalyticsReport() {
    await lastValueFrom(
      this.analyticsService.getPlayersRound()
        .pipe(
          tap(response => window.location.href = response.link),
          catchError(error => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return throwError(error);
          })
        )
    );
  }

   downloadDiffReport() {
     this.dialog
       .open(DiffReportComponent,{
         width: '660px',
       }).afterClosed()
       .pipe(
         takeUntil(this.unsubscribe$),
         filter(Boolean),
         tap(()=> {
         this.snackBarService.showSnackBar('Differance report downloaded');
       })).subscribe()
  }



  addLeaderbord() {
    this.dialog.open(SelectLeaderboardTypeModalComponent,
      {
        disableClose: true,
        autoFocus: false,
        width: '660px',
        panelClass:['prize-builder'],
      })

  }

  addStreakLeaderboard() {
    this.dialog.open(CreateSpecialLeaderboardComponent,
      {
        disableClose: true,
        autoFocus: false,
        width: '660px',
        height: '700px',
        panelClass:['prize-builder'],
      })
  }

  toggleLeaderboardNotification(isShow) {
    this.customLeaderboardService.toggleNotification(isShow).pipe(
      tap(() => {
        this.customLeaderboardService.showNotificationSnackBar(isShow);
      }),
      take((1))
    ).subscribe();
  }

  addNewVendor() {
    this.dialog
      .open(CreateVendorModalComponent,{
        maxHeight:'860px',
        width:'660px',
        height:'90%',
        panelClass:['prize-builder'],
      })
  }

  async onClearCache() {
    await lastValueFrom(
      this.dialogService.openClearCacheConfirmationPopup()
        .pipe(
          switchMap((response) => {
            if (response) {
              return this.adminsService.clearCache()
                .pipe(tap(() => this.snackBarService.showSnackBar('Cache cleaned successfully')))
            } else {
              return of(null);
            }
          }),
          catchError((error) => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return throwError(error);
          })
        )
    );
  }

  addNewLanguage() {
    this.dialog
      .open(AddNewLocaleComponent,{
        maxHeight:'860px',
        width:'660px',
        panelClass:['prize-builder'],
      })
  }

  createStreakGame() {
    this.dialog
      .open(CreateStreakComponent,{
        height: '80%',
        maxHeight:'860px',
        autoFocus: false,
        disableClose: true,
        width:'660px',
        panelClass:['builder-with-tabs'],
        data: {}
      })
  }

  onChangeMaintenance(maintenanceModeEnabled) {
    const body = {maintenanceModeEnabled: !maintenanceModeEnabled};
    this.adminsService.updateMaintenanceStatus(body).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => this.adminsService.maintenanceStatus$.next(body));
  }

  protected readonly HeaderButtonsEnum = HeaderButtonsEnum;
}
