import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidatorFn, Validators,
} from "@angular/forms";
import { BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class TiersValidatorHelperService {

  isStreak$ = new BehaviorSubject(true);

  maxPointsGreaterThanMinPointsValidator(): ValidatorFn {
    return (formGroup: FormGroup) => {

      const minPoints = formGroup.get('minPoints')?.value;
      const maxPoints = formGroup.get('maxPoints')?.value;


      if (!minPoints || !maxPoints) {
        return null;
      }

      if (parseInt(maxPoints) <= parseInt(minPoints)) {
        return { maxPointsGreaterThanMinPoints: true };
      }
      return null;
    };
  }

  maxPositionGreaterThanMinPositionValidator(): ValidatorFn {
    return (formGroup: FormGroup) => {

      const minPosition = formGroup.get('minPosition')?.value;
      const maxPosition = formGroup.get('maxPosition')?.value;


      if (!minPosition || !maxPosition) {
        return null;
      }

      if (parseInt(maxPosition) <= parseInt(minPosition)) {
        return { maxPositionGreaterThanMinPosition: true };
      }
      return null;
    };
  }

 // Helper function to merge errors
  mergeErrors(existingErrors: any, newError: any): any {
    if (!existingErrors) {
      return newError;
    }
    return { ...existingErrors, ...newError };
  }

  tiersValidator(): ValidatorFn {
    return (formArray: AbstractControl): { [key: string]: any } | null => {
      const pointsArray = (formArray as FormArray).controls;

      if (pointsArray.length > 1) {
        for (let index = 1; index < pointsArray.length; index++) {
          const currentControl = pointsArray[index];
          const prevControl = pointsArray[index - 1];

          const minPoints = currentControl.get('minPoints');
          const maxPoints = currentControl.get('maxPoints');
          const minPosition = currentControl.get('minPosition');
          const exactPoints = currentControl.get('exactPoints');

          const prevMinPoints = prevControl.get('minPoints')?.value;
          const prevMaxPosition = prevControl.get('maxPosition')?.value;
          const prevExactPoints = prevControl.get('exactPoints')?.value;

          // Validation for minPoints
          const currentMinPoints = +minPoints.value;
          if (currentMinPoints && minPoints.hasValidator(Validators.required)) {
            if (prevMinPoints <= currentMinPoints) {
              minPoints.setErrors(this.mergeErrors(minPoints.errors, { invalidPoints: true }));
            }
          }

          // Validation for maxPoints
          const currentMaxPoints = +maxPoints.value;
          if (currentMaxPoints && maxPoints.hasValidator(Validators.required)) {
            if (currentMaxPoints >= prevMinPoints) {
              maxPoints.setErrors(this.mergeErrors(maxPoints.errors, { invalidMaxPoints: true }));
            } else {
              const errors = { ...maxPoints.errors };
              delete errors['invalidMaxPoints'];
              maxPoints.setErrors(Object.keys(errors).length ? errors : null);
            }
          }

          // Validation for exactPoints
          const currentExactPoints = +exactPoints.value;
          if (currentExactPoints && exactPoints.hasValidator(Validators.required)) {
            if (currentExactPoints >= prevExactPoints) {
              exactPoints.setErrors(this.mergeErrors(exactPoints.errors, { invalidTierExactPoints: true }));
            } else {
              const errors = { ...exactPoints.errors };
              delete errors['invalidTierExactPoints'];
              exactPoints.setErrors(Object.keys(errors).length ? errors : null);
            }
          }

          if (!this.isStreak$.value) {
            // Validation for minPosition
            const currentMinPosition = +minPosition.value;
            if (currentMinPosition && minPosition.hasValidator(Validators.required)) {
              if (prevMaxPosition >= currentMinPosition) {
                minPosition.setErrors(this.mergeErrors(minPosition.errors, { invalidMinPosition: true }));
              }
            }
          }

        }
      }

      return null;
    };
  }

}
