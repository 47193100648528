<div class="builder-container">
  <div class="builder-header">
    <div class="header-pannel">
      <div class="general-header">
        <mat-icon class="material-icons close" (click)="close()">close</mat-icon>
        <h2 class="popup-header">{{ data ? 'Edit IP Address' : 'Add new IP Address'}}</h2>
      </div>
    </div>
  </div>
  <div class="builder-body">
    <div class="builder-content">
      <div class="class-details">
        <form [formGroup]="form">
          <custom-input
            [placeholder]="'Enter IP Address'"
            formControlName="ip">
            IP Address*
          </custom-input>

          <custom-input
            [placeholder]="'Enter description'"
            formControlName="description">
            Description*
          </custom-input>

        </form>
        <div class="buttons-container">
          <button mat-raised-button color="primary" [disabled]="form.invalid || !form.dirty" (click)="saveWhitelistUser()">Save</button>
          <button mat-raised-button (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
