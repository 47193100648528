export const APP_DATA = {
  MANDATORY: 'Please, fill this field',
  CONTRACT_ADDRESS: 'Must be valid Contract Address',
  WALLET: 'Must be a valid wallet address',
  OPENSEA_URL: 'Must be valid Opensea URL',
  MANDATORY_IMAGE: 'The image is mandatory',
  BIG_IMAGE: 'File is too large. Max size is 4mb',
  MANDATORY_SELECT: 'Please, select item',
  MANDATORY_PRIZE: 'Please, select prize',
  ADD_PRIZE: 'Please, add prize',
  PRIZE_REMAINING: 'Please, select prize with remaining count more than 0',
  PASSWORD_DONT_MATCH: 'Passwords do not match. Please, try again',
  DATES_ERROR: 'Close date can`t be before Open date',
  DATES_ERROR_START: 'Start date can`t be after Close date',
  OPEN_DATE_ERROR: 'Open date can`t be before current date',
  CLOSE_DATE_ERROR: 'Close date can`t be before current date',
  EMAIL_ERROR: 'Incorrect email address',
  PHONE_ERROR: 'Incorrect phone',
  INCLUDE_DIGIT: 'Must include a digit',
  INCLUDE_LOWER_CASE: 'Must contain a lowercase letter',
  INCLUDE_UPPER_CASE: 'Must contain an uppercase letter',
  MIN_LENGHT: 'Must contain at least 8 characters',
  MIN_LENGHT_1: 'Must contain at least 1 characters',
  MIN_LENGHT_2: 'Must contain at least 2 characters',
  MIN_LENGHT_3: 'Must contain at least 3 characters',
  MAX_LENGHT: 'Must contain 20 characters maximum',
  MAX_LENGHT_15: 'Must contain 15 characters maximum',
  MAX_LENGHT_25: 'Must contain 25 characters maximum',
  MAX_LENGHT_200: 'Must contain 200 characters maximum',
  MAX_LENGHT_128: 'Must contain 128 characters maximum',
  MAX_LENGHT_120: 'Must contain 120 characters maximum',
  MAX_LENGHT_255: 'Must contain 255 characters maximum',
  MAX_LENGHT_70: 'Must contain 70 characters maximum',
  MAX_LENGHT_50: 'Must contain 50 characters maximum',
  MAX_LENGHT_52: 'Must contain 52 characters maximum',
  MAX_LENGHT_20: 'Must contain 20 characters maximum',
  MAX_LENGHT_32: 'Must contain 32 characters maximum',
  MAX_LENGHT_30: 'Must contain 30 characters maximum',
  MAX_RANGE: 'Max Value must be equal or less than 9999',
  MAX_VALUE: 'Value must be equal or less than ',
  MIN_RANGE:  'Min Value must be greater than 0',
  MIN_VALUE:  'Must be equal or greater than ',
  MAX_VALUE_LONG:  'Max value is ',
  IVALID_VALUE:  'Invalid value ',
  SHOULD_CONTAIN_PLUS: ' Please enter the maximum value as "N+".',
  SPACE_END:  'Name should not ends with space',
  INTEGER_VALUE: 'Only unsigned integer numbers are allowed',
  SPECIAL_CHARACTER_EXCLUDE: 'Please, remove unsupported characters',
  //eslint-disable-next-line
  SPECIAL_CHARACTERS: 'Must contain a special character: #?!\[\]~`@$%^&\' * -<>(){}|\\/"',
  URL: 'Must be url address',
  DOUBLE_SPACE: 'Must not include double spaces',
  TWO_ANSWERS_ONLY: 'Must contain two answers',
  TWO_ANSWERS: 'At least two answers',
  THREE_ANSWERS: 'At least three answers',
  RANGE_MIN_VALUE_ERROR: 'The Min Value can’t be equal or greater than the Max Value',
  RANGE_INCREMENTAL_VALUE_ERROR: 'The increment can’t be 0 or greater than the difference between Max and Min Values',
  RANGE_INCREMENTAL_ALIQUOT_ERROR: 'Invalid increment value: sequential adding of an increment to the Min Value should be equal to Max Value',
  WEBSITE_PREFIX_VALIDATOR:'Must contain only lower case letters, numbers or hyphens',
  DUPLICATE_EMAIL:'Some of entered emails are duplicated',
  //eslint-disable-next-line
  EMAIL_REGEX: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  EXIST_ANSWER: 'Answer group with those {answer_count} already exist',
  EXTERNAL: 'External',
  SEARCH_EXTERNAL_EVENTS: 'Please, select date',
  TEXT_VARIABLE: 'To display back-end data in the  iframe, please leave the text inside {} unchanged.',
  MIN_MAX_POINTS_ERROR: 'Max points should be more than min points',
  MIN_POINTS_ERROR: 'Min Points should be less than min points of previous range',
  MAX_POINTS_ERROR: 'Max Points should be less than min points of previous range',
  TIERS_PRIZE_ERROR: 'Prize amount should be less than prize amount of previous tier',
  TIERS_EXACT_POINTS_ERROR: 'Points should be less than points of previous tier',
  ZERO_VALUE_ERROR: 'Value cannot be 0',
  MIN_MAX_POSITION_ERROR: 'Max position should be more than min position',
  MIN_POSITION_ERROR: 'Min position should be more than max position of previous range',
  MAX_POSITION_ERROR: 'Max position should be less than min position of previous range',
  FORBIDDEN_IP: 'This IP Address already exists'
};

export const APP_CONFIG = {

}
