import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundWizzardComponent } from './round-wizzard.component';
import { CommonComponentsModule } from "@components/common-components.module";
import { MatGridListModule } from '@angular/material/grid-list';
import {
  RoundWizzardHeaderComponent
} from './common-wizzard-components/round-wizzard-header/round-wizzard-header.component';
import { MaterialModule } from "../material/material.module";
import { WizzardSectionComponent } from './common-wizzard-components/wizzard-section/wizzard-section.component';
import { DetailsSectionComponent } from './wizzard-sections/details-section/details-section.component';
import { PredictionQuestionsSectionComponent } from './wizzard-sections/prediction-questions-section/prediction-questions-section.component';
import { EventScoreSectionComponent } from './wizzard-sections/event-score-section/event-score-section.component';
import { PredictionPrizeSectionComponent } from './wizzard-sections/prediction-prize-section/prediction-prize-section.component';
import { PredictionQuestionComponent } from './common-wizzard-components/prediction-question/prediction-question.component';
import { ReactiveFormsModule } from "@angular/forms";
import { PrizeItemComponent } from "./wizzard-sections/prediction-prize-section/prize-item/prize-item.component";
import { CorrectAnswersPredictionSectionComponent } from './wizzard-sections/correct-answers-prediction-section/correct-answers-prediction-section.component';
import { NewRoundTypeSelectModalComponent } from './new-round-type-select-modal/new-round-type-select-modal.component';
import { PipesModule } from '@modules/pipes/pipes.module';
import { CreateAnswerGroupModalComponent } from './create-answer-group-modal/create-answer-group-modal.component';
import { AnswerGroupsService } from './answer-groups.service';
import { RounWizzardAnswerService } from './round-wizard-answer.service';
import { QuestionTypeService } from './common-wizzard-components/prediction-question/question-type.service';
import { PredictionQuestionFormSectionService } from './prediction-question-section-form.service';
import { CreateAnswerGroupModalService } from './create-answer-group-modal/create-answer-group-modal.service';
import { EditCloseDateTimeModalComponent } from './edit-close-datetime-modal/edit-close-datetime-modal.component';
import { DirectivesModule } from "@modules/directives/directives.module";
import { EditBonusModalComponent } from './edit-bonus-modal/edit-bonus-modal.component';
import { RoundWizzardRoutingModule } from "@app/new-round-wizzard/round-wizzard-routing.module";


@NgModule({
  declarations: [
    RoundWizzardComponent,
    RoundWizzardHeaderComponent,
    WizzardSectionComponent,
    DetailsSectionComponent,
    PredictionQuestionsSectionComponent,
    EventScoreSectionComponent,
    PredictionPrizeSectionComponent,
    PredictionQuestionComponent,
    PrizeItemComponent,
    CorrectAnswersPredictionSectionComponent,
    NewRoundTypeSelectModalComponent,
    CreateAnswerGroupModalComponent,
    EditCloseDateTimeModalComponent,
    EditBonusModalComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    CommonComponentsModule,
    MatGridListModule,
    ReactiveFormsModule,
    PipesModule,
    DirectivesModule,
    RoundWizzardRoutingModule
  ],
  providers: [
    AnswerGroupsService,
    RounWizzardAnswerService,
    QuestionTypeService,
    PredictionQuestionFormSectionService,
    CreateAnswerGroupModalService,
  ],
})
export class RoundWizzardModule {
}
